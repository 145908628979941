import JwtService from "@/core/services/JwtService";

/**
 * @description get all user roles
 */
 export const getRoles = (): Array<string> => {
  const rolesStr = JwtService.getRoleStr();
  if (!rolesStr) {
    return [];
  }
  try {
    const roles = rolesStr.split(",");
    return roles
  } catch (err) {
    return [];
  }
};

export default {
  getRoles,
};