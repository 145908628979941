import ApiService from "@/core/services/ApiService";
import {
  ISearchResult,
  IBaseModule,
  IKpiResultSearchParam,
  IKpiSesult,
  IKpiSetting,
  IKpiSearchParam,
  IKpiSettingReport,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class KpiModule extends VuexModule implements IBaseModule {
  errors = {};
  kpiResult = {} as IKpiSesult;
  kpi = {} as IKpiSetting;
  kpis = {} as ISearchResult<IKpiSetting>;
  unitOptions = [] as Array<string>;
  kpisReport = {} as ISearchResult<IKpiSettingReport>;

  /**
   * Get kpi errors
   * @returns array
   */
  get getKpiErrors() {
    return this.errors;
  }

  /**
   * Get kpi result
   * @returns IKpiSesult
   */
  get getKpiResult(): IKpiSesult {
    return this.kpiResult;
  }

  /**
   * Get kpi
   * @returns IKpiSetting
   */
  get getKpi(): IKpiSetting {
    return this.kpi;
  }

  /**
   * Get kpis
   * @returns ISearchResult<IKpiSetting>
   */
  get getKpis(): ISearchResult<IKpiSetting> {
    return this.kpis;
  }

  /**
   * Get unit options
   * @returns Array<string>
   */
  get getUnitOptions(): Array<string> {
    return this.unitOptions;
  }

  /**
   * Get kpis report
   * @returns ISearchResult<IKpiSettingReport>
   */
  get getKpisReport(): ISearchResult<IKpiSettingReport> {
    return this.kpisReport;
  }

  @Mutation
  [Mutations.SET_KPI_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_KPI](result: IKpiSetting) {
    this.kpi = result;
  }

  @Mutation
  [Mutations.SET_KPIS](result: ISearchResult<IKpiSetting>) {
    this.kpis = result;
  }

  @Mutation
  [Mutations.SET_KPI_RESULT](result: IKpiSesult) {
    this.kpiResult = result;
  }

  @Mutation
  [Mutations.SET_UNIT_OPTIONS](result: Array<string>) {
    this.unitOptions = result;
  }

  @Mutation
  [Mutations.SET_KPIS_REPORT](result: ISearchResult<IKpiSetting>) {
    this.kpisReport = result;
  }

  @Action
  [Actions.LOAD_KPI_RESULT](param: IKpiResultSearchParam) {
    ApiService.setHeader();
    let query = "?utcOffsetMinutes=" + new Date().getTimezoneOffset();
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (!param.hideNegativeValues) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=false";
    }
    if (param.useMetric) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "useMetric=" + param.useMetric;
    }
    if (param.locationId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationId;
    }
    if (param.deviceIds && param.deviceIds.length != 0) {
      return ApiService.post(
        Endpoints.KpiResult + query,
        param.deviceIds as any
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_KPI_RESULT, data);
        })
        .catch(({ response }) => {
          if (!response) {
            this.context.commit(Mutations.SET_KPI_ERROR, [
              "There was an error loading KPI result.",
            ]);
            return;
          }
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_KPI_ERROR, [message]);
        });
    }

    return ApiService.get(Endpoints.KpiResult, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_KPI_RESULT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_KPI_ERROR, [
            "There was an error loading KPI result.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_KPI_ERROR, [message]);
      });
  }

  @Action
  [Actions.SEARCH_KPIS](param: IKpiSearchParam) {
    this.context.commit(Mutations.SET_KPI_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.Kpis, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_KPIS, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_KPI_ERROR, [
            "There was an error loading kpis.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_KPI_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_KPI](kpiId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Kpis, kpiId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_KPI, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_KPI_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_KPI](param: IKpiSetting) {
    this.context.commit(Mutations.SET_KPI_ERROR, []);
    const req = {
      name: param.name,
      expressionMetric: param.expressionMetric,
      expressionImperial: param.expressionImperial,
      unitOfMeasurementMetric: param.unitOfMeasurementMetric,
      unitOfMeasurementImperial: param.unitOfMeasurementImperial,
      icon: param.icon,
      description: param.description,
      tooltip: param.tooltip,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Kpis, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_KPI_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_KPI](param: IKpiSetting) {
    this.context.commit(Mutations.SET_KPI_ERROR, []);
    const req = {
      name: {
        op: "Replace",
        value: param.name,
      },
      description: {
        op: "Replace",
        value: param.description,
      },
      expressionMetric: {
        op: "Replace",
        value: param.expressionMetric,
      },
      expressionImperial: {
        op: "Replace",
        value: param.expressionImperial,
      },
      unitOfMeasurementMetric: {
        op: "Replace",
        value: param.unitOfMeasurementMetric,
      },
      unitOfMeasurementImperial: {
        op: "Replace",
        value: param.unitOfMeasurementImperial,
      },
    } as any;
    if (param.icon) {
      req.icon = {
        op: "Replace",
        value: param.icon,
      };
    }
    if (param.tooltip) {
      req.tooltip = {
        op: "Replace",
        value: param.tooltip,
      };
    }
    ApiService.setHeader();
    return ApiService.patch(Endpoints.Kpis + "/" + param.kpiId, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_KPI_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_KPI](param: IKpiSetting) {
    this.context.commit(Mutations.SET_KPI_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Kpis, param.kpiId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_KPI_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_UNIT_OPTIONS]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Kpis + "/units")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_UNIT_OPTIONS, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_KPI_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_CLIENT_KPIS](clientId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Kpis + "/client/" + clientId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENT_KPIS, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_KPI_ERROR, [message]);
      });
  }

  @Action
  async [Actions.LOAD_CLIENT_KPIS_METADATA](clientId: string) {
    ApiService.setHeader();
    try {
      const meta = await ApiService.head(
        Endpoints.Kpis + "/client/" + clientId
      );
      if (meta.headers && meta.headers["x-custom-kpi-list"]) {
        const result = meta.headers["x-custom-kpi-list"] == "True";
        this.context.commit(Mutations.SET_CLIENT_CUSTOM_KPIS, result);
      }
    } catch (err) {
      this.context.commit(Mutations.SET_KPI_ERROR, err);
    }
  }

  @Action
  [Actions.LOAD_KPIS_REPORT](param: IKpiSearchParam) {
    this.context.commit(Mutations.SET_KPI_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.Kpis + "/export", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_KPIS_REPORT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_KPI_ERROR, [
            "There was an error loading kpis export.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_KPI_ERROR, [message]);
      });
  }
}
