import ApiService from "@/core/services/ApiService";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { IBaseModule } from "../common/Types";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class FileModule extends VuexModule implements IBaseModule {
  errors = {};

  /**
   * Get file errors
   * @returns array
   */
  get getFileErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_FILE_ERROR](error) {
    this.errors = { ...error };
  }

  @Action
  [Actions.UPLOAD_FILE](param) {
    if (!param.serialNumber || !param.content || !param.share || !param.path) {
      this.context.commit(Mutations.SET_ERROR, [
        "There was an error uploading this file. Param not set.",
      ]);
      return;
    }
    this.context.commit(Mutations.SET_FILE_ERROR, []);
    const req = {
      path: param.path + param.serialNumber + ".csv",
      share: param.share,
      content: param.content,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Files, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);

        if (!response || message === undefined || message === null || message === "") {
          this.context.commit(Mutations.SET_FILE_ERROR, [
            "There was an error uploading this document",
          ]);
          return;
        }
        this.context.commit(Mutations.SET_FILE_ERROR, [message]);
      });
  }

  @Action
  [Actions.SEND_FILE_QUEUE](param) {
    this.context.commit(Mutations.SET_FILE_ERROR, []);
    const req = {
      message: param.message,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Queues + "/" + param.queueName, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_FILE_ERROR, [message]);
      });
  }
}
