import ApiService from "@/core/services/ApiService";
import { ITimeZone, ISearchResult, IBaseModule } from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class TimeZoneModule extends VuexModule implements IBaseModule {
  errors = {};
  timeZones = {} as ISearchResult<ITimeZone>;

  /**
   * Get time zone errors
   * @returns array
   */
  get getTimeZoneErrors() {
    return this.errors;
  }

  /**
   * Get time zones
   * @returns ISearchResult<ITimeZone>
   */
  get getTimeZones(): ISearchResult<ITimeZone> {
    return this.timeZones;
  }

  @Mutation
  [Mutations.SET_TIME_ZONE_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_TIME_ZONES](timeZones) {
    this.timeZones = timeZones;
  }

  @Action
  [Actions.LOAD_TIME_ZONES]() {
    ApiService.setHeader();
    const query = "?page=0";
    return ApiService.get(Endpoints.TimeZones, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TIME_ZONES, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_TIME_ZONE_ERROR, [
            "There was an error loading time zones.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_TIME_ZONE_ERROR, [message]);
      });
  }
}
