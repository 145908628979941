import ApiService from "@/core/services/ApiService";
import {
  IClient,
  IBaseModule,
  IClientSearchParam,
  ISearchResult,
  IPinCodeExtension,
  ILocationExtension,
  IUserExtension,
  IRole,
  IDeviceExtension,
  IIndustry,
  IKpiSetting,
  IClientReport,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class ClientModule extends VuexModule implements IBaseModule {
  errors = {};
  client = {} as IClient;
  clients = {} as ISearchResult<IClient>;
  clientLocations = {} as ISearchResult<ILocationExtension>;
  clientUsers = {} as ISearchResult<IUserExtension>;
  clientDevices = {} as ISearchResult<IDeviceExtension>;
  clientPinCodes = {} as Array<IPinCodeExtension>;
  clientKpis = {} as ISearchResult<IKpiSetting>;
  clientCustomKpi = false;
  industries = {} as ISearchResult<IIndustry>;
  clientsReport = {} as ISearchResult<IClientReport>;

  /**
   * Get client errors
   * @returns array
   */
  get getClientErrors() {
    return this.errors;
  }

  /**
   * Get client
   * @returns IClient
   */
  get getClient(): IClient {
    return this.client;
  }

  /**
   * Get clients
   * @returns ISearchResult<IClient>
   */
  get getClients(): ISearchResult<IClient> {
    return this.clients;
  }

  /**
   * Get client pin codes
   * @returns Array<IPinCodeExtension>
   */
  get getClientPinCodes(): Array<IPinCodeExtension> {
    return this.clientPinCodes;
  }

  /**
   * Get client locations
   * @returns ISearchResult<ILocationExtension>
   */
  get getClientLocations(): ISearchResult<ILocationExtension> {
    return this.clientLocations;
  }

  /**
   * Get client users
   * @returns ISearchResult<IUserExtension>
   */
  get getClientUsers(): ISearchResult<IUserExtension> {
    return this.clientUsers;
  }

  /**
   * Get client users
   * @returns ISearchResult<IDeviceExtension>
   */
  get getClientDevices(): ISearchResult<IDeviceExtension> {
    return this.clientDevices;
  }

  /**
   * Get client kpis
   * @returns ISearchResult<IKpiSetting>
   */
  get getClientKpis(): ISearchResult<IKpiSetting> {
    return this.clientKpis;
  }

  /**
   * Get client custom kpi flag
   * @returns boolean
   */
  get getClientCustomKpi(): boolean {
    return this.clientCustomKpi;
  }

  /**
   * Get clients report
   * @returns ISearchResult<IClientReport>
   */
  get getClientsReport(): ISearchResult<IClientReport> {
    return this.clientsReport;
  }

  @Mutation
  [Mutations.SET_CLIENT_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_CLIENT](client: IClient) {
    this.client = client;
  }

  @Mutation
  [Mutations.SET_CLIENTS](clients: ISearchResult<IClient>) {
    this.clients = clients;
  }

  @Mutation
  [Mutations.SET_CLIENT_PINCODES](codes: Array<IPinCodeExtension>) {
    this.clientPinCodes = codes;
  }

  @Mutation
  [Mutations.SET_CLIENT_LOCATIONS](
    locations: ISearchResult<ILocationExtension>
  ) {
    this.clientLocations = locations;
  }

  @Mutation
  [Mutations.SET_CLIENT_USERS](users: ISearchResult<IUserExtension>) {
    this.clientUsers = users;
  }

  @Mutation
  [Mutations.SET_CLIENT_ROLE_DETAIL](data: ISearchResult<IRole>) {
    if (!data || !this.clientUsers || !this.clientUsers.items) {
      return;
    }
    for (var j = 0; j < this.clientUsers.items.length; j++) {
      const roles: IRole[] = data.items.filter((role) => {
        return (role as IRole).roleId === this.clientUsers.items[j].roleId;
      });

      if (roles && roles.length > 0) {
        this.clientUsers.items[j].role = roles[0].description;
      }
    }
  }

  @Mutation
  [Mutations.SET_CLIENT_DEVICES](devices: ISearchResult<IDeviceExtension>) {
    this.clientDevices = devices;
  }

  @Mutation
  [Mutations.SET_CLIENT_KPIS](kpis: ISearchResult<IKpiSetting>) {
    this.clientKpis = kpis;
  }

  @Mutation
  [Mutations.SET_CLIENT_CUSTOM_KPIS](flag: boolean) {
    this.clientCustomKpi = flag;
  }

  @Mutation
  [Mutations.SET_CLIENTS_REPORT](report: ISearchResult<IClientReport>) {
    this.clientsReport = report;
  }

  @Action
  [Actions.CREATE_CLIENT](param) {
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    const req = {
      name: param.name,
      accountNumber: param.accountNumber,
      resellerId: param.resellerId,
      industryId: param.industryId,
      isActive: true,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Clients, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_CLIENT](clientId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Clients, clientId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENT, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_CLIENT](client: IClient) {
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    ApiService.setHeader();
    const req = {
      name: {
        op: "Replace",
        value: client.name,
      },
      accountNumber: {
        op: "Replace",
        value: client.accountNumber,
      },
    } as any;

    if (client.resellerId) {
      req.resellerId = {
        op: "Replace",
        value: client.resellerId,
      }
    }
    else {
      req.resellerId = {
        op: "Remove",
      }
    }

    if (client.industryId) {
      req.industryId = {
        op: "Replace",
        value: client.industryId,
      }
    }
    else {
      req.industryId = {
        op: "Remove",
      }
    }

    ApiService.setHeader();
    return ApiService.patch(Endpoints.Clients + "/" + client.clientId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENT_ERROR, {});
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_CLIENT](client: IClient) {
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Clients, client.clientId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }

  @Action
  [Actions.SEARCH_CLIENTS](param: IClientSearchParam) {
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }
    if (param.accountNumber) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "accountNumber=" + param.accountNumber;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.hasReseller) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hasReseller=" + param.hasReseller;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    query += "&isActive=true";
    return ApiService.get(Endpoints.Clients, query)
      .then(({ data }) => {
        switch (param.category.toLowerCase()) {
          case "resellerclient":
            this.context.commit(Mutations.SET_RESELLER_CLIENTS, data);
            break;

          case "avlresellerclient":
            this.context.commit(Mutations.SET_AVAILABLE_RESELLER_CLIENTS, data);
            break;

          case "clients":
          default:
            this.context.commit(Mutations.SET_CLIENTS, data);
            break;
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_CLIENT_ERROR, [
            "There was an error searching clients.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }

  @Action
  [Actions.GENERATE_CLIENT_KEY](clientId: string) {
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    const req = {
      clientId: clientId,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Clients + "/" + clientId + "/apikey", req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_CLIENT_KPI](param) {
    if (!param || !param.kpiId || param.sortOrder == undefined || !param.clientId) {
      return;
    }
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    const req = {
      kpiId: param.kpiId,
      order: param.sortOrder,
    } as any;
    ApiService.setHeader();
    return ApiService.post(
      Endpoints.Clients + "/" + param.clientId + "/kpis",
      req
    )
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_CLIENT_ERROR, [
          response.data?.errors[0]?.errorMessage,
        ]);
      });
  }

  @Action
  [Actions.UPDATE_CLIENT_KPI](param) {
    if (!param || !param.kpiId || param.sortOrder == undefined || !param.clientId) {
      return;
    }
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    const req = {
      order: {
        op: "Replace",
        value: param.sortOrder,
      },
    } as any;
    ApiService.setHeader();
    return ApiService.patch(
      Endpoints.Clients + "/" + param.clientId + "/kpis/" + param.kpiId,
      req
    )
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_CLIENT_KPI](param) {
    if (!param || !param.kpiId || !param.clientId) {
      return;
    }
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(
      Endpoints.Clients + "/" + param.clientId + "/kpis/" + param.kpiId
    )
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }

  @Action
  [Actions.ENABLE_CLIENT_KPI](param) {
    if (!param.clientId) {
      return;
    }
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    const req = {
      clientId: param.clientId,
    } as any;
    ApiService.setHeader();
    return ApiService.post(
      Endpoints.Clients + "/" + param.clientId + "/kpis/enable", req
    )
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }

  @Action
  [Actions.DISABLE_CLIENT_KPI](param) {
    if (!param.clientId) {
      return;
    }
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    const req = {
      clientId: param.clientId,
    } as any;
    ApiService.setHeader();
    return ApiService.post(
      Endpoints.Clients + "/" + param.clientId + "/kpis/disable", req
    )
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }
  
  @Action
  [Actions.LOAD_CLIENTS_REPORT](param: IClientSearchParam) {
    this.context.commit(Mutations.SET_CLIENT_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }
    if (param.accountNumber) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "accountNumber=" + param.accountNumber;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.hasReseller) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hasReseller=" + param.hasReseller;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    query += "&isActive=true";
    return ApiService.get(Endpoints.Clients + "/export", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CLIENTS_REPORT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_CLIENT_ERROR, [
            "There was an error loading clients export.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_CLIENT_ERROR, [message]);
      });
  }
}
