import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import createPersistedState from "vuex-persistedstate";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ResellerModule from "@/store/modules/ResellerModule";
import UserModule from "@/store/modules/UserModule";
import ClientModule from "@/store/modules/ClientModule";
import LocationModule from "@/store/modules/LocationModule";
import DeviceModule from "@/store/modules/DeviceModule";
import DevicePoolModule from "@/store/modules/DevicePoolModule";
import DeviceReadingModule from "@/store/modules/DeviceReadingModule";
import PinCodeModule from "@/store/modules/PinCodeModule";
import KpiModule from "@/store/modules/KpiModule";
import FileModule from "@/store/modules/FileModule";
import EmailModule from "@/store/modules/EmailModule";
import ModelSizeModule from "@/store/modules/ModelSizeModule";
import IndustryModule from "@/store/modules/IndustryModule";
import PinCodeTypeModule from "@/store/modules/PinCodeTypeModule";
import ChartModule from "@/store/modules/ChartModule";
import DocumentModule from "@/store/modules/DocumentModule";
import DeviceStatusModule from "@/store/modules/DeviceStatusModule";
import TimeZoneModule from "@/store/modules/TimeZoneModule";
import MeasurementStandardModule from "@/store/modules/MeasurementStandardModule";
import CountryProvinceModule from "@/store/modules/CountryProvinceModule";
import LanguageStringModule from "@/store/modules/LanguageStringModule";
config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ResellerModule,
    UserModule,
    ClientModule,
    LocationModule,
    DeviceModule,
    DevicePoolModule,
    PinCodeModule,
    KpiModule,
    DeviceReadingModule,
    FileModule,
    EmailModule,
    ModelSizeModule,
    IndustryModule,
    PinCodeTypeModule,
    ChartModule,
    DocumentModule,
    DeviceStatusModule,
    TimeZoneModule,
    MeasurementStandardModule,
    CountryProvinceModule,
    LanguageStringModule,
  },
  plugins: [
    createPersistedState({
      key: "ORCA",
      storage: {
        getItem: (key) => window.sessionStorage.getItem(key),
        setItem: (key, value) => {
          if (value) {
            let obj = JSON.parse(value);
            delete (obj as any).DevicePoolModule; //Delete redundent module to prevent storage overflow.
            window.sessionStorage.setItem(key, JSON.stringify(obj));
          } else window.sessionStorage.setItem(key, value);
        },
        removeItem: (key) => window.sessionStorage.removeItem(key),
      },
    }),
  ],
});

export default store;
