import ApiService from "@/core/services/ApiService";
import {
  IModelSize,
  IBaseModule,
  IModelSizeSearchParam,
  ISearchResult,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class ModelSizeModule extends VuexModule implements IBaseModule {
  errors = {};
  modelSize = {} as IModelSize;
  modelSizes = {} as ISearchResult<IModelSize>;

  /**
   * Get model size errors
   * @returns array
   */
  get getModelSizeErrors() {
    return this.errors;
  }

  /**
   * Get model size
   * @returns ISearchResult<IModelSize>
   */
  get getModelSize(): IModelSize {
    return this.modelSize;
  }

  /**
   * Get model size options
   * @returns ISearchResult<IModelSize>
   */
  get getModelSizes(): ISearchResult<IModelSize> {
    return this.modelSizes;
  }

  @Mutation
  [Mutations.SET_MODEL_SIZE_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_MODEL_SIZE](modelSize: IModelSize) {
    this.modelSize = modelSize;
  }
  @Mutation
  [Mutations.SET_MODEL_SIZES](modelSizes: ISearchResult<IModelSize>) {
    this.modelSizes = modelSizes;
  }

  @Action
  [Actions.SEARCH_MODEL_SIZES](param: IModelSizeSearchParam) {
    ApiService.setHeader();
    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.ModelSizes, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MODEL_SIZES, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_MODEL_SIZE_ERROR, [
            "There was an error loading model sizes.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_MODEL_SIZE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_MODEL_SIZE](modelSizeId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.ModelSizes, modelSizeId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MODEL_SIZE, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_MODEL_SIZE_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_MODEL_SIZE](param: IModelSize) {
    this.context.commit(Mutations.SET_MODEL_SIZE_ERROR, []);
    const req = {
      name: param.name,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.ModelSizes, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_MODEL_SIZE_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_MODEL_SIZE](param: IModelSize) {
    this.context.commit(Mutations.SET_MODEL_SIZE_ERROR, []);
    const req = {
      name: {
        op: "Replace",
        value: param.name,
      },
    } as any;
    ApiService.setHeader();
    return ApiService.patch(Endpoints.ModelSizes + "/" + param.modelSizeId, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_MODEL_SIZE_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_MODEL_SIZE](param: IModelSize) {
    this.context.commit(Mutations.SET_MODEL_SIZE_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.ModelSizes, param.modelSizeId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_MODEL_SIZE_ERROR, [message]);
      });
  }
}
