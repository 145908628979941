import ApiService from "@/core/services/ApiService";
import {
  ICountry,
  IProvince,
  ISearchResult,
  IBaseModule,
  ICountrySearchParam,
  IProvinceSearchParam,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class CountryProvinceModule
  extends VuexModule
  implements IBaseModule
{
  errors = {};
  countries = {} as ISearchResult<ICountry>;
  country = {} as ICountry;
  provinces = {} as ISearchResult<IProvince>;
  allProvinces = {} as Array<IProvince>;
  province = {} as IProvince;
  selectedCountry = "";
  filteredProvinces = {} as Array<IProvince>;

  /**
   * Get country province errors
   * @returns array
   */
  get getCountryProvinceErrors() {
    return this.errors;
  }

  /**
   * Get countries
   * @returns ISearchResult<ICountry>
   */
  get getCountries(): ISearchResult<ICountry> {
    return this.countries;
  }

  /**
   * Get country
   * @returns ICountry
   */
  get getCountry(): ICountry {
    return this.country;
  }

  /**
   * Get provinces
   * @returns ISearchResult<IProvince>;
   */
  get getProvinces(): ISearchResult<IProvince> {
    return this.provinces;
  }

  /**
   * Get all provinces
   * @returns Array<IProvince>;
   */
  get getAllProvinces(): Array<IProvince> {
    return this.allProvinces;
  }

  /**
   * Get province
   * @returns IProvince;
   */
  get getProvince(): IProvince {
    return this.province;
  }

  /**
   * Get filtered provinces
   * @returns Array<IProvince>
   */
  get getFilteredProvinces(): Array<IProvince> {
    return this.filteredProvinces;
  }

  @Mutation
  [Mutations.SET_COUNTRY_PROVINCE_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_COUNTRIES](countries: ISearchResult<ICountry>) {
    this.countries = countries;
  }

  @Mutation
  [Mutations.SET_COUNTRY](country: ICountry) {
    this.country = country;
  }

  @Mutation
  [Mutations.SET_ALL_PROVINCES](provinces: ISearchResult<IProvince>) {
    this.allProvinces = provinces.items as Array<IProvince>;
  }

  @Mutation
  [Mutations.SET_PROVINCES](provinces) {
    this.provinces = provinces;
  }

  @Mutation
  [Mutations.SET_PROVINCE](pronvince) {
    this.province = pronvince;
  }

  @Mutation
  [Mutations.FILTER_PROVINCES](countryId) {
    if (countryId) {
      this.filteredProvinces = this.allProvinces.filter(function (pl) {
        return pl.parentKey === countryId;
      });
    } else {
      this.filteredProvinces = this.allProvinces;
    }
  }

  @Action
  [Actions.SEARCH_COUNTRIES](param: ICountrySearchParam) {
    ApiService.setHeader();
    let query = "?";
    if (param.value) {
      query += "value=" + param.value;
    }
    if (param.lookupValue) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "lookupValue=" + param.lookupValue;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.Countries, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COUNTRIES, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [
            "There was an error loading countries.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_COUNTRY](countryId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Countries, countryId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COUNTRY, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_COUNTRY](param: ICountry) {
    this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, []);
    const req = {
      value: param.value,
      altValue: param.altValue,
      isActive: param.isActive,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Countries, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_COUNTRY](param: ICountry) {
    this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, []);
    const req = {
      value: {
        op: "Replace",
        value: param.value,
      },
      altValue: {
        op: "Replace",
        value: param.altValue,
      },
      isActive: {
        op: "Replace",
        value: param.isActive,
      },
    } as any;
    ApiService.setHeader();
    return ApiService.patch(Endpoints.Countries + "/" + param.countryId, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_COUNTRY](param: ICountry) {
    this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Countries, param.countryId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [message]);
      });
  }

  @Action
  [Actions.SEARCH_PROVINCES](param: IProvinceSearchParam) {
    ApiService.setHeader();
    let query = "?";
    if (param.value) {
      query += "value=" + param.value;
    }
    if (param.countryId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "countryId=" + param.countryId;
    }
    if (param.lookupValue) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "lookupValue=" + param.lookupValue;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.Provinces, query)
      .then(({ data }) => {
        switch (param.category) {
          case "allProvinces":
            this.context.commit(Mutations.SET_ALL_PROVINCES, data);
            break;

          case "provinces":
          default:
            this.context.commit(Mutations.SET_PROVINCES, data);
            break;
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [
            "There was an error loading provinces.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_PROVINCE](provinceId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Provinces, provinceId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROVINCE, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_PROVINCE](param: IProvince) {
    this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, []);
    const req = {
      value: param.value,
      parentKey: param.parentKey,
      isActive: param.isActive,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Provinces, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_PROVINCE](param: IProvince) {
    this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, []);
    const req = {
      value: {
        op: "Replace",
        value: param.value,
      },
      isActive: {
        op: "Replace",
        value: param.isActive,
      },
    } as any;
    ApiService.setHeader();
    return ApiService.patch(Endpoints.Provinces + "/" + param.provinceId, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_PROVINCE](param: IProvince) {
    this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Provinces, param.provinceId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_COUNTRY_PROVINCE_ERROR, [message]);
      });
  }
}
