import ApiService from "@/core/services/ApiService";
import { IBaseModule } from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class DocumentModule extends VuexModule implements IBaseModule {
  errors = {};

  /**
   * Get document errors
   * @returns array
   */
  get getDocumentErrors() {
    return this.errors;
  }

  @Action
  [Actions.DOWNLOAD_MANUAL](manualName: string) {
    this.context.commit(Mutations.SET_ERROR, []);
    ApiService.setHeader();
    const query =
      process.env.VUE_APP_FILE_ENVIRONMENT + "/troubleshooting/" + manualName;
    return ApiService.get(Endpoints.Files, query)
      .then(({ data }) => {
        var atData = Uint8Array.from(atob(data[0].content), (c) =>
          c.charCodeAt(0)
        );
        var blob = new Blob([atData], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", manualName);
        document.body.appendChild(link);
        link.click();
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error downloading the manual.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_ERROR, [message]);
      });
  }
}
