import ApiService from "@/core/services/ApiService";
import {
  ILocation,
  IBaseModule,
  ILocationSearchParam,
  ISearchResult,
  ILocationExtension,
  IGeolocation,
  ILocationReport,
  IDeviceExtension,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class LocationModule extends VuexModule implements IBaseModule {
  errors = {};
  location = {} as ILocationExtension;
  locations = {} as ISearchResult<ILocationExtension>;
  locationsReport = {} as ISearchResult<ILocationReport>;
  geolocation = {} as IGeolocation;
  limitLocationSet = {} as ISearchResult<ILocationExtension>;
  limitLocations = {} as Array<string>;
  locationDevices = {} as ISearchResult<IDeviceExtension>;

  /**
   * Get location errors
   * @returns array
   */
  get getLocationErrors() {
    return this.errors;
  }

  /**
   * Get locations
   * @returns ISearchResult<ILocationExtension>
   */
  get getLocations(): ISearchResult<ILocationExtension> {
    return this.locations;
  }

  /**
   * Get location
   * @returns ILocation
   */
  get getLocation(): ILocationExtension {
    return this.location;
  }

  /**
   * Get geolocation
   * @returns IGeolocation
   */
  get getGeolocation(): IGeolocation {
    return this.geolocation;
  }

  /**
   * Get limit location set
   * @returns ISearchResult<ILocationExtension>
   */
  get getLimitLocationSet(): ISearchResult<ILocationExtension> {
    return this.limitLocationSet;
  }

  /**
   * Get limit location set
   * @returns ISearchResult<ILocationExtension>
   */
  get getLimitLocations(): Array<string> {
    return this.limitLocations;
  }

  /**
   * Get locations report
   * @returns ISearchResult<ILocationReport>
   */
  get getLocationsReport(): ISearchResult<ILocationReport> {
    return this.locationsReport;
  }

  /**
   * Get location users
   * @returns ISearchResult<IDeviceExtension>
   */
  get getLocationDevices(): ISearchResult<IDeviceExtension> {
    return this.locationDevices;
  }

  @Mutation
  [Mutations.SET_LOCATION_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_LOCATION](location: ILocation) {
    this.location = location as ILocationExtension;
  }

  @Mutation
  [Mutations.SET_LOCATIONS](locations: ISearchResult<ILocation>) {
    this.locations = locations as ISearchResult<ILocationExtension>;
  }

  @Mutation
  [Mutations.SET_GEOLOCATION](geoInfo: IGeolocation) {
    this.geolocation = geoInfo;
  }

  @Mutation
  [Mutations.SET_LIMIT_LOCATION_SET](locations: ISearchResult<ILocation>) {
    this.limitLocationSet = locations as ISearchResult<ILocationExtension>;
  }

  @Mutation
  [Mutations.SET_LIMIT_LOCATIONS](locations: ISearchResult<ILocation>) {
    this.limitLocations = locations.items.map((a) => a.locationId);
  }

  @Mutation
  [Mutations.SET_LOCATIONS_REPORT](report: ISearchResult<ILocationReport>) {
    this.locationsReport = report;
  }

  @Mutation
  [Mutations.SET_LOCATION_DEVICES](devices: ISearchResult<IDeviceExtension>) {
    this.locationDevices = devices;
  }

  @Action
  [Actions.LOAD_LOCATION](locationId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Locations, locationId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LOCATION, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LOCATION_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_GEOLOCATION](param: ILocation) {
    ApiService.setHeader();
    let query = "?";
    if (param.address1) {
      query += "address=" + param.address1;
    }
    if (param.city) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "city=" + param.city;
    }
    if (param.stateProvinceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "provinceId=" + param.stateProvinceId;
    }
    if (param.zipPostalCode) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "postalCode=" + param.zipPostalCode;
    }
    return ApiService.get(Endpoints.Geolocation, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_GEOLOCATION, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LOCATION_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_LOCATION](param) {
    this.context.commit(Mutations.SET_LOCATION_ERROR, []);
    const req = {
      name: param.name,
      address1: param.address1,
      address2: param.address2,
      city: param.city,
      countryId: param.countryId,
      stateProvinceId: param.stateProvinceId,
      zipPostalCode: param.zipPostalCode,
      clientId: param.clientId,
      resellerId: param.resellerId,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Locations, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LOCATION_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_LOCATION](location: ILocation) {
    this.context.commit(Mutations.SET_LOCATION_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Locations, location.locationId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LOCATION_ERROR, [message]);
      });
  }

  @Action
  [Actions.SEARCH_LOCATIONS](param: ILocationSearchParam) {
    this.context.commit(Mutations.SET_LOCATION_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }
    if (param.address1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "address1=" + param.address1;
    }
    if (param.city) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "city=" + param.city;
    }
    if (param.countryId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "countryId=" + param.countryId;
    }
    if (param.stateProvinceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "stateProvinceId=" + param.stateProvinceId;
    }
    if (param.zipPostalCode) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "zipPostalCode=" + param.zipPostalCode;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.userId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "userId=" + param.userId;
    }
    if (param.deviceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "deviceId=" + param.deviceId;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    query += "&isActive=true";
    return ApiService.get(Endpoints.Locations, query)
      .then(({ data }) => {
        switch (param.category.toLowerCase()) {
          case "resellerlocations":
            this.context.commit(Mutations.SET_RESELLER_LOCATIONS, data);
            break;

          case "clientlocations":
            this.context.commit(Mutations.SET_CLIENT_LOCATIONS, data);
            break;

          case "limitlocationset":
            this.context.commit(Mutations.SET_LIMIT_LOCATION_SET, data);
            break;

          case "limitlocations":
            this.context.commit(Mutations.SET_LIMIT_LOCATIONS, data);
            break;

          case "locations":
          default:
            this.context.commit(Mutations.SET_LOCATIONS, data);
            break;
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_LOCATION_ERROR, [
            "There was an error searching locations.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LOCATION_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_LOCATION](location: ILocation) {
    this.context.commit(Mutations.SET_LOCATION_ERROR, []);
    ApiService.setHeader();
    const req = {
      name: {
        op: "Replace",
        value: location.name,
      },
      address1: {
        op: "Replace",
        value: location.address1,
      },
      address2: {
        op: "Replace",
        value: location.address2,
      },
      city: {
        op: "Replace",
        value: location.city,
      },
      countryId: {
        op: "Replace",
        value: location.countryId,
      },
      stateProvinceId: {
        op: "Replace",
        value: location.stateProvinceId,
      },
      zipPostalCode: {
        op: "Replace",
        value: location.zipPostalCode,
      },
      clientId: {
        op: "Replace",
        value: location.clientId,
      },
    } as any;
    if (location.resellerId) {
      req.resellerId = {
        op: "Replace",
        value: location.resellerId,
      };
    }
    ApiService.setHeader();
    return ApiService.patch(
      Endpoints.Locations + "/" + location.locationId,
      req
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LOCATION_ERROR, {});
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LOCATION_ERROR, [message]);
      });
  }

  @Action
  [Actions.ADD_USER_LOCATIONS](payload) {
    this.context.commit(Mutations.SET_LOCATION_ERROR, []);
    if (!payload || !payload.userId || payload.locations.length == 0) {
      this.context.commit(Mutations.SET_LOCATION_ERROR, [
        "There was an error adding a location to an user.",
      ]);
      return;
    }
    ApiService.setHeader();
    for (let index = 0; index < payload.locations.length; index++) {
      const locationId = payload.locations[index];
      ApiService.post(
        Endpoints.Locations + "/" + locationId + "/user/" + payload.userId,
        payload
      )
        .then(({ data }) => {
          //Do nothing
        })
        .catch(({ response }) => {
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_LOCATION_ERROR, [message]);
        });
    }
  }

  @Action
  [Actions.DELETE_USER_LOCATIONS](payload) {
    this.context.commit(Mutations.SET_LOCATION_ERROR, []);
    if (!payload || !payload.userId || payload.locations.length == 0) {
      this.context.commit(Mutations.SET_LOCATION_ERROR, [
        "There was an error deleting a location from an user.",
      ]);
      return;
    }
    ApiService.setHeader();
    for (let index = 0; index < payload.locations.length; index++) {
      const locationId = payload.locations[index];
      ApiService.delete(
        Endpoints.Locations + "/" + locationId + "/user/" + payload.userId
      )
        .then(({ data }) => {
          //Do nothing
        })
        .catch(({ response }) => {
          const message = getErrorMessage(response);
          this.context.commit(Mutations.SET_LOCATION_ERROR, [message]);
        });
    }
  }

  @Action
  [Actions.LOAD_LOCATIONS_REPORT](param: ILocationSearchParam) {
    this.context.commit(Mutations.SET_LOCATION_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }
    if (param.address1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "address1=" + param.address1;
    }
    if (param.city) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "city=" + param.city;
    }
    if (param.countryId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "countryId=" + param.countryId;
    }
    if (param.stateProvinceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "stateProvinceId=" + param.stateProvinceId;
    }
    if (param.zipPostalCode) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "zipPostalCode=" + param.zipPostalCode;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.userId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "userId=" + param.userId;
    }
    if (param.deviceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "deviceId=" + param.deviceId;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    query += "&isActive=true";
    return ApiService.get(Endpoints.Locations + "/export", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LOCATIONS_REPORT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_LOCATION_ERROR, [
            "There was an error loading locations export.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LOCATION_ERROR, [message]);
      });
  }
}
