import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import { vue3Debounce } from "vue-debounce";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "@vuepic/vue-datepicker/dist/main.css";
import "vue3-carousel/dist/carousel.css";
import Notifications from "@kyvg/vue3-notification";
import "@/assets/site.css";
import CKEditor from "@ckeditor/ckeditor5-vue";
import VueDragscroll from "vue-dragscroll";
import Datepicker from "@vuepic/vue-datepicker";
import "@/core/plugins/prismjs";
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(Notifications);
app.use(CKEditor);
app.use(VueDragscroll);
app.component("v-select", vSelect);
app.component("Datepicker", Datepicker);
app.directive("debounce", vue3Debounce({ lock: true }));

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
