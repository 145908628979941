import ApiService from "@/core/services/ApiService";
import { IBaseModule, ILanguageString } from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class LanguageStringModule
  extends VuexModule
  implements IBaseModule
{
  errors = {};
  languageString = {} as ILanguageString;
  languageStrings = {} as Array<ILanguageString>;
  languageStringMetricOptions = {} as Array<ILanguageString>;
  languageStringUnitOptions = {} as Array<ILanguageString>;

  /**
   * Get language string errors
   * @returns array
   */
  get getLanguageStringErrors() {
    return this.errors;
  }

  /**
   * Get language string
   * @returns Array<ILanguageString>
   */
  get getLanguageString(): ILanguageString {
    return this.languageString;
  }

  /**
   * Get language strings
   * @returns Array<ILanguageString>
   */
  get getLanguageStrings(): Array<ILanguageString> {
    return this.languageStrings;
  }

  /**
   * Get language string metric options
   * @returns Array<ILanguageString>
   */
  get getLanguageStringMetricOptions(): Array<ILanguageString> {
    return this.languageStringMetricOptions;
  }

  /**
   * Get language string unit options
   * @returns Array<ILanguageString>
   */
  get getLanguageStringUnitOptions(): Array<ILanguageString> {
    return this.languageStringUnitOptions;
  }

  @Mutation
  [Mutations.SET_LANGUAGE_STRING_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_LANGUAGE_STRING](languageString: ILanguageString) {
    this.languageString = languageString;
  }
  @Mutation
  [Mutations.SET_LANGUAGE_STRINGS](languageStrings: Array<ILanguageString>) {
    this.languageStrings = languageStrings;

    this.languageStringMetricOptions = languageStrings.filter(function (pl) {
      return pl.keyName.startsWith("Metric");
    });

    this.languageStringUnitOptions = languageStrings.filter(function (pl) {
      return !pl.keyName.startsWith("Metric");
    });
  }

  @Action
  [Actions.SEARCH_LANGUAGE_STRINGS](param: ILanguageString) {
    ApiService.setHeader();
    const query = param.applicationName + "/" + param.language;
    return ApiService.get(Endpoints.Languages, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LANGUAGE_STRINGS, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_LANGUAGE_STRING_ERROR, [
            "There was an error loading language strings.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LANGUAGE_STRING_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_LANGUAGE_STRING](param: ILanguageString) {
    ApiService.setHeader();
    const query =
      param.applicationName + "/" + param.language + "/" + param.keyName;
    return ApiService.get(Endpoints.Languages, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LANGUAGE_STRING, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LANGUAGE_STRING_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_LANGUAGE_STRING](param: ILanguageString) {
    this.context.commit(Mutations.SET_LANGUAGE_STRING_ERROR, []);
    const req = {
      applicationName: param.applicationName,
      keyName: param.keyName,
      language: param.language,
      content: param.content,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Languages, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LANGUAGE_STRING_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_LANGUAGE_STRING](param: ILanguageString) {
    this.context.commit(Mutations.SET_LANGUAGE_STRING_ERROR, []);
    const req = {
      content: {
        op: "Replace",
        value: param.content,
      },
    } as any;
    ApiService.setHeader();
    const query =
      param.applicationName + "/" + param.language + "/" + param.keyName;
    return ApiService.patch(Endpoints.Languages + "/" + query, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LANGUAGE_STRING_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_LANGUAGE_STRING](param: ILanguageString) {
    this.context.commit(Mutations.SET_LANGUAGE_STRING_ERROR, []);
    ApiService.setHeader();
    const query =
      param.applicationName + "/" + param.language + "/" + param.keyName;
    return ApiService.delete(Endpoints.Languages, query)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_LANGUAGE_STRING_ERROR, [message]);
      });
  }
}
