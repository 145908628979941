import ApiService from "@/core/services/ApiService";
import {
  IReseller,
  IResellerModule,
  IUserExtension,
  IClient,
  ILocationExtension,
  IResellerSearchParam,
  ISearchResult,
  IRole,
  IDevice,
  IPinCodeExtension,
  IResellerReport,
  IProvince,
  ICountry,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class ResellerModule
  extends VuexModule
  implements IResellerModule
{
  errors = {};
  reseller = {} as IReseller;
  resellers = {} as ISearchResult<IReseller>;
  resellerUsers = {} as ISearchResult<IUserExtension>;
  resellerAvailableUsers = {} as ISearchResult<IUserExtension>;
  resellerClients = {} as ISearchResult<IClient>;
  resellerAvailableClients = {} as ISearchResult<IClient>;
  resellerLocations = {} as ISearchResult<ILocationExtension>;
  resellerDevices = {} as ISearchResult<IDevice>;
  resellerPinCodes = {} as Array<IPinCodeExtension>;
  resellersReport = {} as ISearchResult<IResellerReport>;

  /**
   * Get reseller
   * @returns IReseller
   */
  get getReseller(): IReseller {
    return this.reseller;
  }

  /**
   * Get resellers
   * @returns ISearchResult<IReseller>
   */
  get getResellers(): ISearchResult<IReseller> {
    return this.resellers;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getResellerErrors() {
    return this.errors;
  }

  /**
   * Get reseller users
   * @returns ISearchResult<IUserExtension>
   */
  get getResellerUsers(): ISearchResult<IUserExtension> {
    return this.resellerUsers;
  }

  /**
   * Get reseller available users
   * @returns ISearchResult<IUserExtension>
   */
  get getResellerAvailableUsers(): ISearchResult<IUserExtension> {
    return this.resellerAvailableUsers;
  }

  /**
   * Get reseller clients
   * @returns ISearchResult<IClient>
   */
  get getResellerClients(): ISearchResult<IClient> {
    return this.resellerClients;
  }

  /**
   * Get reseller available clients
   * @returns ISearchResult<IClient>
   */
  get getResellerAvailableClients(): ISearchResult<IClient> {
    return this.resellerAvailableClients;
  }

  /**
   * Get reseller locations
   * @returns ISearchResult<IClient>
   */
  get getResellerLocations(): ISearchResult<ILocationExtension> {
    return this.resellerLocations;
  }

  /**
   * Get reseller devices
   * @returns ISearchResult<IDevice>
   */
  get getResellerDevices(): ISearchResult<IDevice> {
    return this.resellerDevices;
  }

  /**
   * Get reseller pin codes
   * @returns ISearchResult<IPinCodeExtension>
   */
  get getResellerPinCodes(): Array<IPinCodeExtension> {
    return this.resellerPinCodes;
  }

  /**
   * Get resellers report
   * @returns ISearchResult<IResellerReport>
   */
  get getResellersReport(): ISearchResult<IResellerReport> {
    return this.resellersReport;
  }

  @Mutation
  [Mutations.SET_RESELLER_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_RESELLER](reseller) {
    this.reseller = reseller;
  }

  @Mutation
  [Mutations.CLEAN_RESELLER]() {
    this.reseller = {} as IReseller;
  }

  @Mutation
  [Mutations.SET_RESELLERS](resellers: ISearchResult<IReseller>) {
    this.resellers = resellers;
  }

  @Mutation
  [Mutations.SET_RESELLER_USERS](users: ISearchResult<IUserExtension>) {
    this.resellerUsers = users;
  }

  @Mutation
  [Mutations.SET_RESELLER_AVAILABLE_USERS](
    users: ISearchResult<IUserExtension>
  ) {
    this.resellerAvailableUsers = users;
  }

  @Mutation
  [Mutations.SET_RESELLER_CLIENTS](clients: ISearchResult<IClient>) {
    this.resellerClients = clients;
  }

  @Mutation
  [Mutations.SET_AVAILABLE_RESELLER_CLIENTS](clients: ISearchResult<IClient>) {
    this.resellerAvailableClients = clients;
  }

  @Mutation
  [Mutations.SET_RESELLER_LOCATIONS](
    locations: ISearchResult<ILocationExtension>
  ) {
    this.resellerLocations = locations;
  }

  @Mutation
  [Mutations.SET_RESELLER_PINCODES](codes: Array<IPinCodeExtension>) {
    this.resellerPinCodes = codes;
  }

  @Mutation
  [Mutations.SET_RESELLER_PINCODES_DETAIL]() {
    if (!this.resellerDevices.items) {
      return;
    }
    for (var j = 0; j < this.resellerPinCodes.length; j++) {
      const devices: IDevice[] = this.resellerDevices.items.filter((dev) => {
        return (dev as IDevice).deviceId === this.resellerPinCodes[j].deviceId;
      });

      if (devices && devices.length > 0) {
        this.resellerPinCodes[j].deviceName = devices[0].name;
        this.resellerPinCodes[j].deviceSerialNumber = devices[0].serialNumber;
      }
    }
  }

  @Mutation
  [Mutations.SET_LOCATION_COUNTRY_DETAIL](data) {
    if (
      !data ||
      !data.countries ||
      !data.provinces ||
      !this.resellerLocations ||
      !this.resellerLocations.items
    ) {
      return;
    }
    for (var j = 0; j < this.resellerLocations.items.length; j++) {
      var countries: ICountry[] = data.countries.items.filter((coun) => {
        return (
          (coun as ICountry).countryId ===
          this.resellerLocations.items[j].countryId
        );
      });

      if (countries && countries.length > 0) {
        this.resellerLocations.items[j].country = countries[0].value;
      }

      var provinces: IProvince[] = data.provinces.filter((prov) => {
        return (
          (prov as IProvince).provinceId ===
          this.resellerLocations.items[j].stateProvinceId
        );
      });

      if (provinces && provinces.length > 0) {
        this.resellerLocations.items[j].province = provinces[0].value;
      }
    }
  }

  @Mutation
  [Mutations.SET_RESELLER_USERS_ROLE_DETAIL](data: ISearchResult<IRole>) {
    if (!data || !this.resellerUsers || !this.resellerUsers.items) {
      return;
    }
    for (var j = 0; j < this.resellerUsers.items.length; j++) {
      const roles: IRole[] = data.items.filter((role) => {
        return (role as IRole).roleId === this.resellerUsers.items[j].roleId;
      });

      if (roles && roles.length > 0) {
        this.resellerUsers.items[j].role = roles[0].description;
      }
    }
  }

  @Mutation
  [Mutations.SET_AVAILABLE_USER_ROLE_DETAIL](data: ISearchResult<IRole>) {
    if (
      !data ||
      !this.resellerAvailableUsers ||
      !this.resellerAvailableUsers.items
    ) {
      return;
    }
    for (var j = 0; j < this.resellerAvailableUsers.items.length; j++) {
      const roles: IRole[] = data.items.filter((role) => {
        return (
          (role as IRole).roleId === this.resellerAvailableUsers.items[j].roleId
        );
      });

      if (roles && roles.length > 0) {
        this.resellerAvailableUsers.items[j].role = roles[0].description;
      }
    }
  }

  @Mutation
  [Mutations.SET_RESELLER_DEVICES](clients: ISearchResult<IDevice>) {
    this.resellerDevices = clients;
  }

  @Mutation
  [Mutations.SET_RESELLERS_REPORT](report: ISearchResult<IResellerReport>) {
    this.resellersReport = report;
  }

  @Action
  [Actions.SEARCH_RESELLERS](param: IResellerSearchParam) {
    this.context.commit(Mutations.SET_RESELLER_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.clientId) {
      query = "clientId=" + param.clientId;
    }

    if (param.name) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "name=" + param.name;
    }

    if (param.email) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "email=" + param.email;
    }

    if (param.phone) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "phoneNumber=" + param.phone;
    }

    if (param.page > -1) {      
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }

    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }

    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    
    return ApiService.get(Endpoints.Resellers, query)
      .then(({ data }) => {
        switch (param.category.toLowerCase()) {
          case "resellers":
          default:
            this.context.commit(Mutations.SET_RESELLERS, data);
            break;
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_RESELLER_ERROR, [
            "There was an error loading resellers.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_RESELLER_ERROR, [message]);
      });
  }

  @Action
  async [Actions.LOAD_RESELLER_METADATA]() {
    ApiService.setHeader();
    const resellers = this.resellers;
    if (!resellers) {
      return;
    }
    for (let i = 0; i < resellers.items.length; i++) {
      const meta = await ApiService.head(
        Endpoints.Resellers,
        resellers.items[i].resellerId
      );
      if (meta.data == "") {
        //resellers.items[i].clientCount = 82;
        //resellers.items[i].newestClientDate = new Date();
      }
    }
    this.context.commit(Mutations.SET_RESELLERS, resellers);
  }

  @Action
  [Actions.LOAD_RESELLER](resellerId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Resellers, resellerId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESELLER, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_RESELLER_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_RESELLER](reseller: IReseller) {
    this.context.commit(Mutations.SET_RESELLER_ERROR, []);
    ApiService.setHeader();
    const req = {
      name: {
        op: "Replace",
        value: reseller.name,
      },
      supportPhoneNumber: {
        op: "Replace",
        value: reseller.supportPhoneNumber,
      },
      supportEmail: {
        op: "Replace",
        value: reseller.supportEmail,
      },
    } as any;
    ApiService.setHeader();
    return ApiService.patch(
      Endpoints.Resellers + "/" + reseller.resellerId,
      req
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESELLER_ERROR, {});
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_RESELLER_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_RESELLER](reseller: IReseller) {
    this.context.commit(Mutations.SET_RESELLER_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Resellers, reseller.resellerId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_RESELLER_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_RESELLER](param) {
    this.context.commit(Mutations.SET_RESELLER_ERROR, []);
    const req = {
      name: param.name,
      supportPhoneNumber: param.supportPhoneNumber,
      isActive: true,
      supportEmail: param.supportEmail,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Resellers, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_RESELLER_ERROR, [message]);
      });
  }

  @Action
  [Actions.ADD_RESELLER_CLIENT](payload) {
    this.context.commit(Mutations.SET_RESELLER_ERROR, []);
    if (!payload || !payload.clientId || !payload.resellerId) {
      this.context.commit(Mutations.SET_RESELLER_ERROR, [
        "There was an error adding a client to reseller.",
      ]);
      return;
    }
    ApiService.setHeader();
    return ApiService.post(
      Endpoints.Resellers +
        "/" +
        payload.resellerId +
        "/client/" +
        payload.clientId,
      payload
    )
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_RESELLER_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_RESELLERS_REPORT](param: IResellerSearchParam) {
    this.context.commit(Mutations.SET_RESELLER_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.clientId) {
      query = "clientId=" + param.clientId;
    }

    if (param.name) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "name=" + param.name;
    }

    if (param.email) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "email=" + param.email;
    }

    if (param.phone) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "phoneNumber=" + param.phone;
    }

    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    return ApiService.get(Endpoints.Resellers + "/export", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESELLERS_REPORT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_RESELLER_ERROR, [
            "There was an error loading resellers export.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_RESELLER_ERROR, [message]);
      });
  }
}
