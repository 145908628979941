/**
 * @description get all user roles
 */
export const getErrorMessage = (response) => {
  if (response && response.data) {
    if (response.data.errors && response.data?.errors.length > 0) {
      return response.data?.errors[0]?.errorMessage;
    } else return response.statusText;
  } else return response.statusText;
};

export default {
  getErrorMessage,
};
