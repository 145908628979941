import {
  IDeviceExtension,
  IBaseModule,
  ISearchResult,
} from "@/store/common/Types";
import { Mutations } from "@/store/enums/StoreEnums";
import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class DevicePoolModule
  extends VuexModule
  implements IBaseModule
{
  errors = {};
  deviceIds = [] as Array<string>;
  devices = {} as ISearchResult<IDeviceExtension>;

  /**
   * Get device errors
   * @returns array
   */
  get getDevicePoolErrors() {
    return this.errors;
  }

  /**
   * Get device ids
   * @returns Array<string>
   */
  get getDeviceIds(): Array<string> {
    return this.deviceIds;
  }

  /**
   * Get devices
   * @returns ISearchResult<IDeviceExtension>
   */
  get getDevices(): ISearchResult<IDeviceExtension> {
    return this.devices;
  }

  @Mutation
  [Mutations.SET_DEVICES_IDS_LIST](devices: ISearchResult<IDeviceExtension>) {
    if (devices && devices.items) {
      this.deviceIds = devices.items.map((x) => x.deviceId);
    }
  }

  @Mutation
  [Mutations.SET_DEVICES](devices: ISearchResult<IDeviceExtension>) {
    this.devices = devices;
  }
}
