import ApiService from "@/core/services/ApiService";
import {
  IDevice,
  IDeviceExtension,
  IBaseModule,
  IDeviceSearchParam,
  ISearchResult,
  IPinCodeExtension,
  IDeviceReading,
  IDeviceReport,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class DeviceModule extends VuexModule implements IBaseModule {
  errors = {};
  device = {} as IDevice;

  devicePinCodes = {} as Array<IPinCodeExtension>;
  deviceReadings = {} as ISearchResult<IDeviceReading>;
  recentDevices = {} as ISearchResult<IDeviceExtension>;
  devicesReport = {} as ISearchResult<IDeviceReport>;
  manualUploadSerialNumber = "";
  hideNegativeValues = true as Boolean;

  /**
   * Get device errors
   * @returns array
   */
  get getDeviceErrors() {
    return this.errors;
  }

  /**
   * Get device
   * @returns IDevice
   */
  get getDevice(): IDevice {
    return this.device;
  }

  /**
   * Get device pin codes
   * @returns Array<IPinCodeExtension>
   */
  get getDevicePinCodes(): Array<IPinCodeExtension> {
    return this.devicePinCodes;
  }

  /**
   * Get device device readings
   * @returns ISearchResult<IPinCodeExtension>
   */
  get getDeviceDeviceReadings(): ISearchResult<IDeviceReading> {
    return this.deviceReadings;
  }

  /**
   * Get last device reading
   * @returns getDeviceReadings
   */
  get getLastDeviceReading(): IDeviceReading | null {
    if (
      !this.deviceReadings ||
      !this.deviceReadings.items ||
      (this.deviceReadings.items as Array<IDeviceReading>).length <= 0
    ) {
      return null;
    }
    return this.deviceReadings.items[0];
  }

  /**
   * Get recent devices
   * @returns ISearchResult<IDeviceExtension>
   */
  get getRecentDevices(): ISearchResult<IDeviceExtension> {
    return this.recentDevices;
  }

  /**
   * Get devices report
   * @returns ISearchResult<IDeviceReport>
   */
  get getDevicesReport(): ISearchResult<IDeviceReport> {
    return this.devicesReport;
  }

  /**
   * Get Manual Upload Serial Number
   * @returns string
   */
  get getManualUploadSerialNumber(): string {
    return this.manualUploadSerialNumber;
  }

  /**
   * Get hide negative values flag
   * @returns Boolean
   */
  get getHideNegativeValues(): Boolean {
    return this.hideNegativeValues;
  }

  @Mutation
  [Mutations.SET_DEVICE_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_DEVICE](device: IDevice) {
    this.device = device;
  }

  @Mutation
  [Mutations.SET_RECENT_DEVICES](devices: ISearchResult<IDeviceExtension>) {
    this.recentDevices = devices;
  }

  @Mutation
  [Mutations.SET_DEVICE_PINCODES](pinCodes: Array<IPinCodeExtension>) {
    this.devicePinCodes = pinCodes;
  }

  @Mutation
  [Mutations.SET_DEVICE_DEVICEREADINGS](
    readings: ISearchResult<IDeviceReading>
  ) {
    this.deviceReadings = readings;
  }

  @Mutation
  [Mutations.SET_DEVICES_REPORT](report: ISearchResult<IDeviceReport>) {
    this.devicesReport = report;
  }

  @Mutation
  [Mutations.SET_MANUAL_UPLOAD_DEVICE](serialNumber: string) {
    this.manualUploadSerialNumber = serialNumber;
  }

  @Mutation
  [Mutations.SET_HIDE_NEGATIVE_VALUES](flag: Boolean) {
    this.hideNegativeValues = flag;
  }

  @Action
  [Actions.CREATE_DEVICE](param) {
    this.context.commit(Mutations.SET_DEVICE_ERROR, []);
    const req = {
      name: param.name,
      serialNumber: param.serialNumber,
      piSerialNumber: param.piSerialNumber,
      deviceStatusId: param.deviceStatusId,
      isManual: param.isManual,
      timeZoneId: param.timeZoneId,
      clientId: param.clientId,
      locationId: param.locationId,
      resellerId: param.resellerId,
      modelSizeId: param.modelSizeId,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Devices, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_DEVICE](deviceId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Devices, deviceId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEVICE, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_DEVICE](device: IDevice) {
    this.context.commit(Mutations.SET_DEVICE_ERROR, []);
    ApiService.setHeader();
    const req = {
      name: {
        op: "Replace",
        value: device.name,
      },
      serialNumber: {
        op: "Replace",
        value: device.serialNumber,
      },
      piSerialNumber: {
        op: "Replace",
        value: device.piSerialNumber,
      },
      deviceStatusId: {
        op: "Replace",
        value: device.deviceStatusId,
      },
      isManual: {
        op: "Replace",
        value: device.isManual,
      },
      timeZoneId: {
        op: "Replace",
        value: device.timeZoneId,
      },
      modelSizeId: {
        op: "Replace",
        value: device.modelSizeId,
      },
    } as any;

    if (device.locationId) {
      req.locationId = {
        op: "Replace",
        value: device.locationId,
      }
    }
    else {
      req.locationId = {
        op: "Remove",
      }
    }

    if (device.clientId) {
      req.clientId = {
        op: "Replace",
        value: device.clientId,
      }
    }
    else {
      req.clientId = {
        op: "Remove",
      }
    }

    ApiService.setHeader();
    return ApiService.patch(Endpoints.Devices + "/" + device.deviceId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEVICE_ERROR, {});
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_DEVICE](device: IDevice) {
    this.context.commit(Mutations.SET_DEVICE_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Devices, device.deviceId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_ERROR, [message]);
      });
  }

  @Action
  [Actions.SEARCH_DEVICES](param: IDeviceSearchParam) {
    this.context.commit(Mutations.SET_DEVICE_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }
    if (param.serialNumber) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "serialNumber=" + param.serialNumber;
    }
    if (param.piSerialNumber) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "piSerialNumber=" + param.piSerialNumber;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.locationId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationId;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.isManual != null) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "isManual=" + param.isManual;
    }
    if (param.includeDivertedWeight != null) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "includeDivertedWeight=" + param.includeDivertedWeight;
    }
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString().split("T")[0];
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString().split("T")[0];
    }
    if (param.hideNegativeValues != null) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=" + param.hideNegativeValues;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.Devices, query)
      .then(({ data }) => {
        switch (param.category.toLowerCase()) {
          case "resellerdevices":
            this.context.commit(Mutations.SET_RESELLER_DEVICES, data);
            break;

          case "clientdevices":
            this.context.commit(Mutations.SET_CLIENT_DEVICES, data);
            break;

          case "locationdevices":
            this.context.commit(Mutations.SET_LOCATION_DEVICES, data);
            break;

          case "idlist":
            this.context.commit(Mutations.SET_DEVICES, data);
            this.context.commit(Mutations.SET_DEVICES_IDS_LIST, data);
            break;

          case "devices":
          default:
            this.context.commit(Mutations.SET_DEVICES, data);
            break;
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_DEVICE_ERROR, [
            "There was an error searching digesters.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_RECENT_ADDED_DEVICES]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Devices + "/recent")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RECENT_DEVICES, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_DEVICES_REPORT](param: IDeviceSearchParam) {
    this.context.commit(Mutations.SET_DEVICE_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }
    if (param.serialNumber) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "serialNumber=" + param.serialNumber;
    }
    if (param.piSerialNumber) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "piSerialNumber=" + param.piSerialNumber;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.locationId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationId;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.isManual) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "isManual=" + param.isManual;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.Devices + "/export", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEVICES_REPORT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_DEVICE_ERROR, [
            "There was an error loading digesters export.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_ERROR, [message]);
      });
  }
}
