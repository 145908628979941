import ApiService from "@/core/services/ApiService";
import {
  IMeasurementStandard,
  ISearchResult,
  IBaseModule,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class MeasurementStandardModule
  extends VuexModule
  implements IBaseModule
{
  errors = {};
  measurementStandards = {} as ISearchResult<IMeasurementStandard>;
  measurementMetricId = "";

  /**
   * Get measurement standard errors
   * @returns array
   */
  get getMeasurementStandardErrors() {
    return this.errors;
  }

  /**
   * Get device status
   * @returns ISearchResult<IMeasurementStandard>
   */
  get getMeasurementStandards(): ISearchResult<IMeasurementStandard> {
    return this.measurementStandards;
  }

  /**
   * Get measurement metric id
   * @returns string
   */
  get getMeasurementMetricId(): string {
    return this.measurementMetricId;
  }

  @Mutation
  [Mutations.SET_MEASUREMENT_STANDARD_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_MEASUREMENT_STANDARDS](
    data: ISearchResult<IMeasurementStandard>
  ) {
    this.measurementStandards = data;

    const filteredItem = data.items.filter(function (pl) {
      return pl.value === "Metric";
    });
    if (filteredItem.length != 0) {
      this.measurementMetricId = filteredItem[0].measurementStandardId;
    }
  }

  @Action
  [Actions.LOAD_MEASUREMENT_STANDARDS]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.MeasurementStandards)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MEASUREMENT_STANDARDS, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_MEASUREMENT_STANDARD_ERROR, [
            "There was an error loading measurement standardS.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_MEASUREMENT_STANDARD_ERROR, [
          message,
        ]);
      });
  }
}
