import ApiService from "@/core/services/ApiService";
import {
  ISearchResult,
  IUser,
  IBaseModule,
  IUserSearchParam,
  IUserExtension,
  IRole,
  IUserAvatar,
  IUserAvatarExtension,
  IUserReport,
  ISupport,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";
import store from "@/store";

@Module
export default class UserModule extends VuexModule implements IBaseModule {
  errors = {};
  users = {} as ISearchResult<IUserExtension>;
  userId = "";
  user = {} as IUser;
  roles = {} as ISearchResult<IRole>;
  avatar = {} as IUserAvatar;
  roleIdGlobalAdministrator = "";
  roleIdResellerAdministrator = "";
  roleIdClientAdministrator = "";
  roleIdClientUser = "";
  globalAdminRoles = [] as IRole[];
  clientAdminRoles = [] as IRole[];
  managerRoles = [] as IRole[];
  userClientRoles = [] as IRole[];
  usersReport = {} as ISearchResult<IUserReport>;
  userSupport = {} as ISupport;

  /**
   * Get user errors
   * @returns array
   */
  get getUserErrors() {
    return this.errors;
  }

  /**
   * Get users
   * @returns ISearchResult<IUserExtension>
   */
  get getUsers(): ISearchResult<IUserExtension> {
    return this.users;
  }

  /**
   * Get user
   * @returns IUser
   */
  get getUser(): IUser {
    return this.user;
  }

  /**
   * Get roles
   * @returns ISearchResult<IRole>
   */
  get getRoles(): ISearchResult<IRole> {
    return this.roles;
  }

  /**
   * Get avatar
   * @returns IUserAvatar
   */
  get getAvatar(): IUserAvatar {
    return this.avatar;
  }

  /**
   * Get global administrator role id
   * @returns string
   */
  get getGlobalAdministratorRoleId(): string {
    return this.roleIdGlobalAdministrator;
  }

  /**
   * Get reseller administrator role id
   * @returns string
   */
  get getResellerAdministratorRoleId(): string {
    return this.roleIdResellerAdministrator;
  }

  /**
   * Get client administrator role id
   * @returns string
   */
  get getClientAdministratorRoleId(): string {
    return this.roleIdClientAdministrator;
  }

  /**
   * Get client user role id
   * @returns string
   */
  get getClientUserRoleId(): string {
    return this.roleIdClientUser;
  }

    /**
   * Get user id
   * @returns string
   */
  get getUserId(): string {
    return this.userId;
  }


  /**
   * Get users report
   * @returns ISearchResult<IUserReport>
   */
  get getUsersReport(): ISearchResult<IUserReport> {
    return this.usersReport;
  }

  /**
   * Get global admin roles
   * @returns IRole[]
   */
  get getGlobalAdminRoles(): IRole[] {
    return this.globalAdminRoles;
  }

  /**
   * Get client admin roles
   * @returns IRole[]
   */
  get getClientAdminRoles(): IRole[] {
    return this.clientAdminRoles;
  }

  /**
   * Get manager roles
   * @returns IRole[]
   */
  get getManagerRoles(): IRole[] {
    return this.managerRoles;
  }

  /**
   * Get client user roles
   * @returns IRole[]
   */
  get getClientUserRoles(): IRole[] {
    return this.userClientRoles;
  }

  /**
   * Get user support
   * @returns ISupport
   */
  get getUserSupport(): ISupport {
    return this.userSupport;
  }

  @Mutation
  [Mutations.SET_USER_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_USER](user: IUser) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_SEARCH_USERS](users: ISearchResult<IUserExtension>) {
    this.users = users;
  }

  @Mutation
  [Mutations.SET_USER_ID](userId: string) {
    this.userId = userId;
  }

  @Mutation
  [Mutations.SET_ROLES](roles: ISearchResult<IRole>) {
    this.roles = roles;

    if (!roles || roles.items.length <= 0) {
      return;
    }
    let filteredItem = roles.items.filter(function (pl) {
      return pl.name === "Global Administrator";
    });
    if (filteredItem.length != 0) {
      this.roleIdGlobalAdministrator = filteredItem[0].roleId;
    }

    filteredItem = roles.items.filter(function (pl) {
      return pl.name === "Partner Administrator";
    });
    if (filteredItem.length != 0) {
      this.roleIdResellerAdministrator = filteredItem[0].roleId;
    }

    filteredItem = roles.items.filter(function (pl) {
      return pl.name === "Client Administrator";
    });
    if (filteredItem.length != 0) {
      this.roleIdClientAdministrator = filteredItem[0].roleId;
    }

    filteredItem = roles.items.filter(function (pl) {
      return pl.name === "Client User";
    });
    if (filteredItem.length != 0) {
      this.roleIdClientUser = filteredItem[0].roleId;
    }

    this.globalAdminRoles = roles.items.filter(function (pl) {
      return pl.name === "Global Administrator";
    });

    this.clientAdminRoles = roles.items.filter(function (pl) {
      return pl.name === "Client Administrator";
    });

    this.managerRoles = roles.items.filter(function (pl) {
      return pl.name === "Partner Administrator";
    });

    this.userClientRoles = roles.items.filter(function (pl) {
      return pl.name === "Client User";
    });
  }

  @Mutation
  [Mutations.SET_USER_AVATAR](avatar: IUserAvatar) {
    this.avatar = avatar;
  }

  @Mutation
  [Mutations.SET_USERS_ROLE_DETAIL]() {
    if (!this.roles || !this.roles.items || !this.users || !this.users.items) {
      return;
    }
    for (var j = 0; j < this.users.items.length; j++) {
      const roles: IRole[] = this.roles.items.filter((type) => {
        return (type as IRole).roleId === this.users.items[j].roleId;
      });

      if (roles && roles.length > 0) {
        this.users.items[j].role = roles[0].description;
      }
    }
  }

  @Mutation
  [Mutations.SET_USERS_REPORT](report: ISearchResult<IUserReport>) {
    this.usersReport = report;
  }

  @Mutation
  [Mutations.SET_SUPPORT](result: ISupport) {
    this.userSupport = result;
  }

  SET_SUPPORT;

  @Action
  [Actions.LOAD_USER](userId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Users, userId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_USER_AVATAR](userId: string) {
    this.context.commit(Mutations.SET_USER_AVATAR, {});
    ApiService.setHeader();
    return ApiService.get(Endpoints.Users, userId + "/avatar")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_AVATAR, data);
      })
      .catch(({ response }) => {
        //do nothing
      });
  }

  @Action
  [Actions.CREATE_USER](param) {
    this.context.commit(Mutations.SET_USER_ERROR, []);
    const req = {
      firstName: param.firstName,
      lastName: param.lastName,
      isActive: true,
      emailAddress: param.emailAddress,
      timeZoneId: param.timeZoneId,
      measurementStandardId: param.measurementStandardId,
      clientId: param.clientId,
      userTypeId: param.userTypeId,
      roleId: param.roleId,
      limitResellers: param.limitResellers,
      LimitLocations: param.limitLocations,
      offlineDeviceNotify: param.offlineDeviceNotify,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Users, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_ID, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.SEARCH_USERS](param: IUserSearchParam) {
    this.context.commit(Mutations.SET_USER_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.firstName) {
      query += "firstName=" + param.firstName;
    }
    if (param.lastName) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "lastName=" + param.lastName;
    }
    if (param.emailAddress) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "emailAddress=" + param.emailAddress;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.isAssignedToReseller != null) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "isAssignedToReseller=" + param.isAssignedToReseller;
    }
    if (param.roleId) {
      for (let o = 0; o < param.roleId.length; o++) {
        if (query.slice(-1) !== "?") {
          query += "&";
        }
        query += "roleId=" + param.roleId[o];
      }
    }
    if (param.either) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "either=" + param.either;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    query += "&isActive=true";
    return ApiService.get(Endpoints.Users, query)
      .then(({ data }) => {
        switch (param.category.toLowerCase()) {
          case "resellerusers":
            this.context.commit(Mutations.SET_RESELLER_USERS, data);
            break;

          case "reselleravailableusers":
            this.context.commit(Mutations.SET_RESELLER_AVAILABLE_USERS, data);
            break;

          case "clientusers":
            this.context.commit(Mutations.SET_CLIENT_USERS, data);
            break;

          case "users":
          default:
            this.context.commit(Mutations.SET_SEARCH_USERS, data);
            break;
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_USER_ERROR, [
            "There was an error searching users.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.SEARCH_USERS_SINGLE](param: IUserSearchParam) {
    this.context.commit(Mutations.SET_USER_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.firstName) {
      query += "firstName=" + param.firstName;
    }
    if (param.lastName) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "lastName=" + param.lastName;
    }
    if (param.emailAddress) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "emailAddress=" + param.emailAddress;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (
      param.isAssignedToReseller === true ||
      param.isAssignedToReseller === false
    ) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "isAssignedToReseller=" + param.isAssignedToReseller;
    }
    if (param.roleId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "roleId=" + param.roleId;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    query += "&isActive=true";
    return ApiService.get(Endpoints.Users, query)
      .then(({ data }) => {
        switch (param.category.toLowerCase()) {
          case "resellerusers":
            this.context.commit(Mutations.SET_RESELLER_USERS, data);
            break;

          case "reselleravailableusers":
            this.context.commit(Mutations.SET_RESELLER_AVAILABLE_USERS, data);
            break;

          case "clientusers":
            this.context.commit(Mutations.SET_CLIENT_USERS, data);
            break;

          case "users":
          default:
            this.context.commit(Mutations.SET_SEARCH_USERS, data);
            break;
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_USER_ERROR, [
            "There was an error searching users.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.ADD_RESELLER_USER](payload) {
    this.context.commit(Mutations.SET_USER_ERROR, []);
    if (!payload || !payload.userId || !payload.resellerId) {
      this.context.commit(Mutations.SET_USER_ERROR, [
        "There was an error updating user.",
      ]);
      return;
    }
    const req = {
      resellerId: {
        op: "Replace",
        value: payload.resellerId,
      },
    } as any;
    ApiService.setHeader();
    return ApiService.patch(Endpoints.Users + "/" + payload.userId, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_ROLES]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Roles)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ROLES, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_USER_ERROR, [
            "There was an error loading user roles.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_USER](user: IUser) {
    const userCanChangeRole =
      store.getters.userCanCreateGlobalAdmin ||
      store.getters.userCanCreateClientAdmin ||
      store.getters.userCanCreateResellerAdmin ||
      store.getters.userCanCreateClientUser;

    this.context.commit(Mutations.SET_USER_ERROR, []);
    ApiService.setHeader();
    const req = {
      firstName: {
        op: "Replace",
        value: user.firstName,
      },
      lastName: {
        op: "Replace",
        value: user.lastName,
      },
      emailAddress: {
        op: "Replace",
        value: user.emailAddress,
      },
      userTypeId: {
        op: "Replace",
        value: user.userTypeId,
      },
      measurementStandardId: {
        op: "Replace",
        value: user.measurementStandardId,
      },
      timeZoneId: {
        op: "Replace",
        value: user.timeZoneId,
      },
      isActive: {
        op: "Replace",
        value: user.isActive,
      },
      canViewPinCodeGraphs: {
        op: "Replace",
        value: user.canViewPinCodeGraphs,
      },
      offlineDeviceNotify: {
        op: "Replace",
        value: user.offlineDeviceNotify,
      },
      limitLocations: {
        op: "Replace",
        value: user.limitLocations,
      },
    } as any;
    if (userCanChangeRole && user.roleId) {
      req.roleId = {
        op: "Replace",
        value: user.roleId,
      };
    }
    if (user.clientId) {
      req.clientId = {
        op: "Replace",
        value: user.clientId,
      };
    }
    else {
      req.clientId = {
        op: "Remove",
      };
    }
    if (user.resellerId) {
      req.resellerId = {
        op: "Replace",
        value: user.resellerId,
      };
    }
    else {
      req.resellerId = {
        op: "Remove",
      }
    }
    if (user.limitResellers === true || user.limitResellers === false) {
      req.limitResellers = {
        op: "Replace",
        value: user.limitResellers,
      };
    }
    ApiService.setHeader();
    return ApiService.patch(Endpoints.Users + "/" + user.userId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_ERROR, {});
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.EMAIL_RESET_PASSWORD](user: IUser) {
    this.context.commit(Mutations.SET_USER_ERROR, []);
    ApiService.setHeader();
    const req = {
      data: user,
    } as any;
    return ApiService.post(
      Endpoints.Users + "/forgot?email=" + user.emailAddress,
      req
    )
      .then(() => {
        this.context.commit(Mutations.SET_USER_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response || response.status == 500) {
          this.context.commit(Mutations.SET_USER_ERROR, [
            "An error occurred when sending email to reset the password. Please try again later.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.RESEND_INVITE](user: IUser) {
    this.context.commit(Mutations.SET_USER_ERROR, []);
    ApiService.setHeader();
    const req = {
      data: user,
    } as any;
    return ApiService.post(
      Endpoints.Users + "/" + user.userId + "/resendinvite",
      req
    )
      .then(() => {
        this.context.commit(Mutations.SET_USER_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_USER_ERROR, [
            "We were unable to find a user with that email address. Please confirm you are entering the correct address.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_USER_AVATAR](payload: IUserAvatarExtension) {
    this.context.commit(Mutations.SET_USER_ERROR, []);
    ApiService.setHeader();
    const req = {
      avatar: {
        op: payload.updateType == "update" ? "Replace" : "Remove",
        value: payload.avatar,
      },
    } as any;

    return ApiService.patch(Endpoints.Users + "/" + payload.userId, req)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_USER](user: IUser) {
    this.context.commit(Mutations.SET_USER_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Users, user.userId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_USERS_REPORT](param: IUserSearchParam) {
    this.context.commit(Mutations.SET_USER_ERROR, []);
    ApiService.setHeader();
    let query = "?";
    if (param.firstName) {
      query += "firstName=" + param.firstName;
    }
    if (param.lastName) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "lastName=" + param.lastName;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (
      param.isAssignedToReseller === true ||
      param.isAssignedToReseller === false
    ) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "isAssignedToReseller=" + param.isAssignedToReseller;
    }
    if (param.roleId) {
      for (let o = 0; o < param.roleId.length; o++) {
        if (query.slice(-1) !== "?") {
          query += "&";
        }
        query += "roleId=" + param.roleId[o];
      }
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    query += "&isActive=true";
    return ApiService.get(Endpoints.Users + "/export", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USERS_REPORT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_USER_ERROR, [
            "There was an error loading users export.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_SUPPORT]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Users + "/support")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SUPPORT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_USER_ERROR, [
            "There was an error loading user support info.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_USER_ERROR, [message]);
      });
  }
}
