enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  FORGOT_PASSWORD = "forgotPassword",
  RESET_PASSWORD = "resetPassword",
  LOAD_PERMISSION = "loadPermission",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  LOAD_LOGIN_USER = "loadLoginUser",
  LOAD_LOGIN_USER_AVATAR = "loadLoginUserAvatar",
  VALIDATE_KEY = "ValidateKey",
  //resellers
  SEARCH_RESELLERS = "searchResellers",
  LOAD_RESELLER = "loadReseller",
  UPDATE_RESELLER = "updateReseller",
  DELETE_RESELLER = "deleteReseller",
  LOAD_RESELLER_METADATA = "loadResellerMeta",
  CREATE_RESELLER = "createReseller",
  ADD_RESELLER_CLIENT = "addResellerClient",
  LOAD_RESELLERS_REPORT = "loadResellersReport",
  //users
  SEARCH_USERS = "searchUsers",
  SEARCH_USERS_SINGLE = "searchUsersSingle",
  LOAD_USER = "loadUser",
  LOAD_USER_AVATAR = "loadUserAvatar",
  CREATE_USER = "createUser",
  UPDATE_USER = "updateUser",
  ADD_RESELLER_USER = "addResellerUser",
  LOAD_ROLES = "loadRoles",
  EMAIL_RESET_PASSWORD = "emailResetPassword",
  UPDATE_USER_AVATAR = "updateUserAvatar",
  RESEND_INVITE = "resendInvite",
  DELETE_USER = "deleteUser",
  LOAD_USERS_REPORT = "loadUsersReport",
  LOAD_SUPPORT = "loadSupport",
  //clients
  SEARCH_CLIENTS = "searchClients",
  CREATE_CLIENT = "createClient",
  LOAD_CLIENT = "loadClient",
  UPDATE_CLIENT = "updateClient",
  DELETE_CLIENT = "deleteClient",
  GENERATE_CLIENT_KEY = "generateClientKey",
  CREATE_CLIENT_KPI = "createClientKpi",
  UPDATE_CLIENT_KPI = "updateClientKpi",
  DELETE_CLIENT_KPI = "deleteClientKpi",
  ENABLE_CLIENT_KPI = "enableClientKpi",
  DISABLE_CLIENT_KPI = "disableClientKpi",
  LOAD_CLIENTS_REPORT = "loadClientsReport",
  //locations
  SEARCH_LOCATIONS = "searchLocations",
  LOAD_LOCATION = "loadLocation",
  CREATE_LOCATION = "createLocation",
  LOAD_GEOLOCATION = "loadGeolocation",
  UPDATE_LOCATION = "updateLocation",
  ADD_USER_LOCATIONS = "addUserLocations",
  DELETE_USER_LOCATIONS = "deleteUserLocations",
  LOAD_LOCATIONS_REPORT = "loadLocationsReport",
  DELETE_LOCATION = "deleteLocation",
  //devices
  SEARCH_DEVICES = "searchDevices",
  LOAD_DEVICE = "loadDevice",
  CREATE_DEVICE = "createDevice",
  UPDATE_DEVICE = "updateDevice",
  DELETE_DEVICE = "deleteDevice",
  LOAD_RECENT_ADDED_DEVICES = "loadRecentAddedDevices",
  LOAD_DEVICES_REPORT = "loadDevicesReport",
  //devicereadings
  SEARCH_DEVICE_READINGS = "searchDeviceReadings",
  LOAD_DEVICE_READINGS_REPORT = "loadDeviceReadingsReport",
  //pincodes
  SEARCH_PINCODES = "searchPinCodes",
  CREATE_PINCODE = "createPinCode",
  UPDATE_PINCODE = "updatePinCode",
  DELETE_PINCODE = "deletePinCode",
  LOAD_RESELLER_PINCODES = "loadResellerPinCodes",
  LOAD_CLIENT_PINCODES = "loadClientPinCodes",
  LOAD_DEVICE_PINCODES = "loadDevicePinCodes",
  LOAD_PINCODES_REPORT = "loadPinCodesReport",
  //kpis
  LOAD_KPI_RESULT = "loadKpiResult",
  SEARCH_KPIS = "searchKpis",
  CREATE_KPI = "createKpi",
  UPDATE_KPI = "updateKpi",
  DELETE_KPI = "deleteKpi",
  LOAD_KPI = "loadKpi",
  LOAD_UNIT_OPTIONS = "loadUnitOptions",
  LOAD_CLIENT_KPIS = "loadClientKpis",
  LOAD_CLIENT_KPIS_METADATA = "loadClientKpisMetadata",
  LOAD_KPIS_REPORT = "loadKpisReport",
  //files
  UPLOAD_FILE = "uploadFile",
  SEND_FILE_QUEUE = "sendFileQueue",
  //email
  SEND_EMAIL = "sendEmail",
  //modelsize
  SEARCH_MODEL_SIZES = "searchModelSizes",
  CREATE_MODEL_SIZE = "createModelSize",
  UPDATE_MODEL_SIZE = "updateModelSize",
  DELETE_MODEL_SIZE = "deleteModelSize",
  LOAD_MODEL_SIZE = "loadModelSize",
  //industry
  SEARCH_INDUSTRIES = "searchIndustries",
  CREATE_INDUSTRY = "createIndustry",
  UPDATE_INDUSTRY = "updateIndustry",
  DELETE_INDUSTRY = "deleteIndustry",
  LOAD_INDUSTRY = "loadIndustry",
  //pin code type
  SEARCH_PIN_CODE_TYPES = "searchPinCodeTypes",
  CREATE_PIN_CODE_TYPE = "createPinCodeType",
  UPDATE_PIN_CODE_TYPE = "updatePinCodeType",
  DELETE_PIN_CODE_TYPE = "deletePinCodeType",
  LOAD_PIN_CODE_TYPE = "loadPinCodeType",
  //chart
  LOAD_MONTHLY_NET_WEIGHT_BY_PIN_CODE = "loadMonthlyNetWeightByPinCode",
  LOAD_MONTHLY_NET_WEIGHT_BY_LOCATION = "loadMonthlyNetWeightByLocation",
  LOAD_TOTAL_NET_WEIGHT_BY_PIN_CODE = "loadTotalNetWeightByPinCode",
  LOAD_TOTAL_NET_WEIGHT_BY_RESELLER = "loadTotalNetWeightByReseller",
  LOAD_TOTAL_NET_WEIGHT_BY_CLIENT = "loadTotalNetWeightByClient",
  LOAD_TOTAL_NET_WEIGHT_BY_LOCATION = "loadTotalNetWeightByLocation",
  LOAD_TOTAL_NET_WEIGHT_BY_DEVICE = "loadTotalNetWeightByDevice",
  LOAD_NET_WEIGHT_BY_DEVICE = "loadNetWeightByDevice",
  //docuement/tool
  DOWNLOAD_MANUAL = "downloadManual",
  //devicestatus
  LOAD_DEVICE_STATUSES = "loadDeviceStatuses",
  //timezone
  LOAD_TIME_ZONES = "loadTimeZones",
  //devicestatus
  LOAD_MEASUREMENT_STANDARDS = "loadMeasurementStandard",
  //country province
  SEARCH_COUNTRIES = "searchCountries",
  LOAD_COUNTRY = "loadCountry",
  CREATE_COUNTRY = "createCountry",
  UPDATE_COUNTRY = "updateCountry",
  DELETE_COUNTRY = "deleteCountry",
  SEARCH_PROVINCES = "searchProvinces",
  LOAD_PROVINCE = "loadProvince",
  CREATE_PROVINCE = "createProvince",
  UPDATE_PROVINCE = "updateProvince",
  DELETE_PROVINCE = "deleteProvince",
  //languagestrings
  SEARCH_LANGUAGE_STRINGS = "searchLanguageStrings",
  CREATE_LANGUAGE_STRING = "createLanguageString",
  UPDATE_LANGUAGE_STRING = "updateLanguageString",
  DELETE_LANGUAGE_STRING = "deleteLanguageString",
  LOAD_LANGUAGE_STRING = "loadLanguageString",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_TOKEN = "setToken",
  SET_LOGIN_USER = "setLoginUser",
  SET_LOGIN_USER_AVATAR = "setLoginUserAvatar",
  SET_PASSWORD = "setPassword",
  SET_PERMISSION = "setPermission",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_KEY_VALID_FLAG = "setKeyValidFlag",
  //resellers
  SET_RESELLER = "setReseller",
  CLEAN_RESELLER = "cleanReseller",
  SET_RESELLERS = "setResellers",
  SET_RESELLER_ERROR = "setResellerError",
  SET_RESELLER_USERS = "setResellerUsers",
  SET_RESELLER_AVAILABLE_USERS = "setResellerAvailableUsers",
  SET_RESELLER_CLIENTS = "setResellerClients",
  SET_AVAILABLE_RESELLER_CLIENTS = "setAvailableResellerClients",
  SET_RESELLER_LOCATIONS = "setResellerLocations",
  SET_RESELLER_DEVICES = "setResellerDevices",
  SET_RESELLER_PINCODES = "setResellerPinCodes",
  SET_RESELLER_PINCODES_DETAIL = "setResellerPinCodesDetail",
  SET_LOCATION_COUNTRY_DETAIL = "setLocationCountryDetail",
  SET_RESELLER_USERS_ROLE_DETAIL = "setResellerUsersRoleDetail",
  SET_AVAILABLE_USER_ROLE_DETAIL = "setAvailableUserRoleDetail",
  SET_RESELLERS_REPORT = "setResellersReport",
  //users
  SET_USER = "setUser",
  SET_USER_ERROR = "setUserError",
  SET_SEARCH_USERS = "setSearchUsers",
  SET_ROLES = "setRoles",
  SET_USER_AVATAR = "setUserAvatar",
  SET_USERS_ROLE_DETAIL = "setUsersRoleDetail",
  SET_USERS_REPORT = "setUsersReport",
  SET_SUPPORT = "setSupport",
  SET_USER_ID = "setUserId",
  //clients
  SET_CLIENT = "setClient",
  SET_CLIENTS = "setClients",
  SET_CLIENT_LOCATIONS = "setClientLocations",
  SET_CLIENT_PINCODES = "setClientPinCodes",
  SET_CLIENT_USERS = "setClientUsers",
  SET_CLIENT_DEVICES = "setClientDevices",
  SET_CLIENT_ERROR = "setClientError",
  SET_CLIENT_ROLE_DETAIL = "setClientRoleDetail",
  SET_CLIENT_KPIS = "setClientKpis",
  SET_CLIENT_CUSTOM_KPIS = "setClientCustomKpis",
  SET_CLIENTS_REPORT = "setClientsReport",
  //locations
  SET_LOCATION = "setLocation",
  SET_LOCATIONS = "setLocations",
  SET_LIMIT_LOCATION_SET = "setLimitLocationSet",
  SET_LIMIT_LOCATIONS = "setLimitLocations",
  SET_GEOLOCATION = "setGeolocation",
  SET_LOCATION_ERROR = "setLocationError",
  SET_LOCATIONS_REPORT = "setLocationsReport",
  SET_LOCATION_DEVICES = "setLocationDevices",
  //devices
  SET_DEVICE = "setDevice",
  SET_DEVICES = "setDevices",
  SET_RECENT_DEVICES = "setRecentDevices",
  SET_DEVICE_ERROR = "setDeviceError",
  SET_DEVICE_PINCODES = "setDevicePinCodes",
  SET_DEVICE_DEVICEREADINGS = "setDeviceDeviceReadings",
  SET_DEVICES_REPORT = "setDevicesReport",
  SET_DEVICES_IDS_LIST = "setDevicesIdsList",
  SET_MANUAL_UPLOAD_DEVICE = "setManualUploadDevice",
  SET_HIDE_NEGATIVE_VALUES = "setHideNegativeValues",
  //devicereadings
  SET_DEVICE_READING_ERROR = "setDeviceReadingError",
  SET_DEVICE_READINGS = "setDeviceReadings",
  SET_DEVICE_READINGS_REPORT = "setDeviceReadingsReport",
  //pincodes
  SET_GLOBAL_PINCODES = "setGlobalPinCodes",
  SET_PINCODES = "setPinCodes",
  SET_PINCODES_DETAIL = "setPinCodesDetail",
  SET_PINCODE_ERROR = "setPinCodeError",
  SET_PINCODES_REPORT = "setPinCodesReport",
  //kpis
  SET_KPI_ERROR = "setKpiError",
  SET_KPI = "setKpi",
  SET_KPIS = "setKpis",
  SET_KPI_RESULT = "setKpiResult",
  SET_UNIT_OPTIONS = "setUnitOptions",
  SET_KPIS_REPORT = "setKpisReport",
  //files
  SET_FILE_ERROR = "setFileError",
  //model sizes
  SET_MODEL_SIZE = "setModelSize",
  SET_MODEL_SIZES = "setModelSizes",
  SET_MODEL_SIZE_ERROR = "setModelSizeError",
  //industries
  SET_INDUSTRY = "setIndustry",
  SET_INDUSTRIES = "setIndustries",
  SET_INDUSTRY_ERROR = "setIndustryError",
  //pin code types
  SET_PIN_CODE_TYPE = "setPinCodeType",
  SET_PIN_CODE_TYPES = "setPinCodeTypes",
  SET_PIN_CODE_TYPE_ERROR = "setPinCodeTypeError",
  //chart
  SET_CHART_ERROR = "setChartError",
  SET_MONTHLY_NET_WEIGHT_BY_PIN_CODE_RESULT = "setMonthlyNetWeightByPinCodeResult",
  SET_MONTHLY_NET_WEIGHT_BY_LOCATION_RESULT = "setMonthlyNetWeightByLocationResult",
  SET_TOTAL_NET_WEIGHT_BY_PIN_CODE_RESULT = "setTotalNetWeightByPinCodeResult",
  SET_TOTAL_NET_WEIGHT_BY_CLIENT_RESULT = "setTotalNetWeightByClientResult",
  SET_TOTAL_NET_WEIGHT_BY_RESELLER_RESULT = "setTotalNetWeightByResellerResult",
  SET_TOTAL_NET_WEIGHT_BY_LOCATION_RESULT = "setTotalNetWeightByLocationResult",
  SET_TOTAL_NET_WEIGHT_BY_DEVICE_RESULT = "setTotalNetWeightByDeviceResult",
  //device status
  SET_DEVICE_STATUS_ERROR = "setDeviceStatusError",
  SET_DEVICE_STATUSES = "setDeviceStatuses",
  //time zone
  SET_TIME_ZONE_ERROR = "setTimeZoneError",
  SET_TIME_ZONES = "setTimeZones",
  //measurement standard
  SET_MEASUREMENT_STANDARD_ERROR = "setMeasurementStandardError",
  SET_MEASUREMENT_STANDARDS = "setMeasurementStandards",
  //country province
  SET_COUNTRY_PROVINCE_ERROR = "setCountryProvinceError",
  SET_COUNTRIES = "setCountries",
  SET_COUNTRY = "setCountry",
  SET_ALL_PROVINCES = "setAllProvinces",
  SET_PROVINCES = "setProvinces",
  SET_PROVINCE = "setProvince",
  FILTER_PROVINCES = "filterProvinces",
  //model sizes
  SET_LANGUAGE_STRING = "setLanguageString",
  SET_LANGUAGE_STRINGS = "setLanguageStrings",
  SET_LANGUAGE_STRING_ERROR = "setLanguageStringError",
}

enum Endpoints {
  Token = "token",
  Resellers = "resellers",
  Users = "users",
  Clients = "clients",
  Locations = "locations",
  Geolocation = "geolocation",
  Devices = "devices",
  DevicePins = "devicepins",
  DeviceReadings = "devicereadings",
  KpiResult = "devicereadings/computedkpi",
  Kpis = "kpis",
  Roles = "roles",
  Files = "files",
  Queues = "queues",
  ModelSizes = "modelsizes",
  Industries = "industries",
  Email = "emailqueue",
  PinCodeTypes = "devicepintypes",
  Charts = "charts",
  DeviceStatuses = "devicestatuses",
  TimeZones = "orcatimezones",
  MeasurementStandards = "measurementstandards",
  Countries = "countries",
  Provinces = "provinces",
  Languages = "languages",
}

export { Actions, Mutations, Endpoints };
