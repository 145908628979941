import {
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
  RouterView,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { IPermission } from "@/store/common/Types";
import { h } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/partners",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "",
            name: "partners",
            component: () => import("@/views/admin/Resellers.vue"),
            meta: {
              requiresAuth: true,
              permissions: ["ResellerView"],
            },
          },
          {
            path: "detail",
            name: "partnerdetail",
            component: () => import("@/views/admin/ResellerDetail.vue"),
            meta: {
              requiresAuth: true,
              permissions: ["ResellerCreate", "ResellerEdit"],
            },
          },
        ],
      },
      {
        path: "/admin/users",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "",
            name: "users",
            component: () => import("@/views/admin/Users.vue"),
            meta: {
              requiresAuth: true,
              permissions: ["ClientUserView"],
            },
          },
          {
            path: "detail",
            name: "userdetail",
            component: () => import("@/views/admin/UserDetail.vue"),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "/admin/locations",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "",
            name: "locations",
            component: () => import("@/views/admin/Locations.vue"),
            meta: {
              requiresAuth: true,
              permissions: ["LocationView"],
            },
          },
          {
            path: "detail",
            name: "locationdetail",
            component: () => import("@/views/admin/LocationDetail.vue"),
            meta: {
              requiresAuth: true,
              permissions: ["LocationView", "LocationEdit"],
            },
          },
        ],
      },
      {
        path: "/admin/digesters",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "",
            name: "digesters",
            component: () => import("@/views/admin/Devices.vue"),
            meta: {
              requiresAuth: true,
              permissions: ["DeviceView"],
            },
          },
          {
            path: "detail",
            name: "devicedetail",
            component: () => import("@/views/admin/DeviceDetail.vue"),
            meta: {
              requiresAuth: true,
              permissions: ["DeviceView", "DeviceEdit"],
            },
          },
        ],
      },
      {
        path: "/admin/clients",
        component: { render: () => h(RouterView) },
        children: [
          {
            path: "",
            name: "clients",
            component: () => import("@/views/admin/Clients.vue"),
            meta: {
              requiresAuth: true,
              permissions: ["ClientView"],
            },
          },
          {
            path: "detail",
            name: "clientdetail",
            component: () => import("@/views/admin/ClientDetail.vue"),
            meta: {
              requiresAuth: true,
              permissions: ["ClientEdit"],
            },
          },
        ],
      },
      {
        path: "/admin/pincodes",
        name: "pincodes",
        component: () => import("@/views/admin/PinCodes.vue"),
        meta: {
          requiresAuth: true,
          permissions: ["DevicePinCreate", "DevicePinEdit"],
        },
      },
      {
        path: "/admin/kpis",
        name: "kpis",
        component: () => import("@/views/admin/KPIs.vue"),
        meta: {
          requiresAuth: true,
          permissions: ["KpiCreate", "KpiEdit"],
        },
      },
      {
        path: "/admin/manualupload",
        name: "manualupload",
        component: () => import("@/views/admin/ManualUpload.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/toolsanddocuments",
        name: "toolsanddocuments",
        component: () => import("@/views/ToolsAndDocuments.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/faqs",
        name: "faqs",
        component: () => import("@/views/Faqs.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/support",
        name: "support",
        component: () => import("@/views/Support.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/modelsizes",
        name: "modelsizes",
        component: () => import("@/views/admin/ModelSizes.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/industries",
        name: "industries",
        component: () => import("@/views/admin/Industries.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/pincodetypes",
        name: "pincodetypes",
        component: () => import("@/views/admin/PinCodeTypes.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/admin/countries",
        name: "countries",
        component: () => import("@/views/admin/Countries.vue"),
        meta: {
          requiresAuth: true,
          permissions: ["CanEditGeography"],
        },
      },
      {
        path: "/admin/provinces",
        name: "provinces",
        component: () => import("@/views/admin/Provinces.vue"),
        meta: {
          requiresAuth: true,
          permissions: ["CanEditGeography"],
        },
      },
      {
        path: "/admin/languageStrings",
        name: "languageStrings",
        component: () => import("@/views/admin/LanguageStrings.vue"),
        meta: {
          requiresAuth: true,
          permissions: ["CanEditLanguageStrings"],
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/password-forget",
        name: "password-forget",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/ForgetPassword.vue"
          ),
      },
      {
        path: "/resetpassword",
        name: "resetpassword",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    // the 404 route, when none of the above matches
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, {
    api_token: JwtService.getToken(),
    refresh_token: JwtService.getRefreshToken(),
  });

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isUserAuthenticated) {
      next("/sign-in");
    } 
    else if(store.getters.isUserAuthenticated &&
      Object.keys(store.getters.currentUser).length === 0){
        store.dispatch(Actions.LOGOUT).then(() => next("/sign-in"));
    }else {
      if (
        to.meta.permissions &&
        (to.meta.permissions as Array<string>).length > 0
      ) {
        const permission = store.getters.getPermission as IPermission;
        if (
          Object.keys(permission).length === 0 ||
          !permission.Permissions.some((p) =>
            (to.meta.permissions as Array<string>).includes(p)
          )
        ) {
          return next("/dashboard");
        }
      }
      next();
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
