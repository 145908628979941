import ApiService from "@/core/services/ApiService";
import {
  IDeviceStatus,
  ISearchResult,
  IBaseModule,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule, Mutation } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class DeviceStatusModule
  extends VuexModule
  implements IBaseModule
{
  errors = {};
  deviceStatuses = {} as ISearchResult<IDeviceStatus>;

  /**
   * Get device status errors
   * @returns array
   */
  get getDeviceStatusErrors() {
    return this.errors;
  }

  /**
   * Get device statuses
   * @returns ISearchResult<IDeviceStatus>
   */
  get getDeviceStatuses(): ISearchResult<IDeviceStatus> {
    return this.deviceStatuses;
  }

  @Mutation
  [Mutations.SET_DEVICE_STATUS_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_DEVICE_STATUSES](statuses) {
    this.deviceStatuses = statuses;
  }

  @Action
  [Actions.LOAD_DEVICE_STATUSES]() {
    ApiService.setHeader();
    return ApiService.get(Endpoints.DeviceStatuses)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEVICE_STATUSES, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_DEVICE_STATUS_ERROR, [
            "There was an error loading device statuses.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_STATUS_ERROR, [message]);
      });
  }
}
