import ApiService from "@/core/services/ApiService";
import {
  IIndustry,
  IBaseModule,
  IIndustrySearchParam,
  ISearchResult,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class IndustryModule extends VuexModule implements IBaseModule {
  errors = {};
  industry = {} as IIndustry;
  industries = {} as ISearchResult<IIndustry>;

  /**
   * Get industry errors
   * @returns array
   */
  get getIndustryErrors() {
    return this.errors;
  }

  /**
   * Get industry
   * @returns ISearchResult<IIndustry>
   */
  get getIndustry(): IIndustry {
    return this.industry;
  }

  /**
   * Get industry options
   * @returns ISearchResult<IIndustry>
   */
  get getIndustries(): ISearchResult<IIndustry> {
    return this.industries;
  }

  @Mutation
  [Mutations.SET_INDUSTRY_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_INDUSTRY](industry: IIndustry) {
    this.industry = industry;
  }
  @Mutation
  [Mutations.SET_INDUSTRIES](industries: ISearchResult<IIndustry>) {
    this.industries = industries;
  }

  @Action
  [Actions.SEARCH_INDUSTRIES](param: IIndustrySearchParam) {
    ApiService.setHeader();
    let query = "?";
    if (param.name) {
      query += "name=" + param.name;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.Industries, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_INDUSTRIES, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_INDUSTRY_ERROR, [
            "There was an error loading industries.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_INDUSTRY_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_INDUSTRY](industryId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.Industries, industryId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_INDUSTRY, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_INDUSTRY_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_INDUSTRY](param: IIndustry) {
    this.context.commit(Mutations.SET_INDUSTRY_ERROR, []);
    const req = {
      name: param.name,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.Industries, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_INDUSTRY_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_INDUSTRY](param: IIndustry) {
    this.context.commit(Mutations.SET_INDUSTRY_ERROR, []);
    const req = {
      name: {
        op: "Replace",
        value: param.name,
      },
    } as any;
    ApiService.setHeader();
    return ApiService.patch(Endpoints.Industries + "/" + param.industryId, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_INDUSTRY_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_INDUSTRY](param: IIndustry) {
    this.context.commit(Mutations.SET_INDUSTRY_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.Industries, param.industryId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_INDUSTRY_ERROR, [message]);
      });
  }
}
