import ApiService from "@/core/services/ApiService";
import {
  IBaseModule,
  IDeviceReadingSearchParam,
  ISearchResult,
  IDeviceReading,
  IDeviceReadingReport,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class DeviceReadingModule
  extends VuexModule
  implements IBaseModule
{
  errors = {};
  deviceReadings = {} as ISearchResult<IDeviceReading>;
  deviceReadingsReport = {} as ISearchResult<IDeviceReadingReport>;

  /**
   * Get device reading errors
   * @returns array
   */
  get getDeviceReadingErrors() {
    return this.errors;
  }

  /**
   * Get device readings
   * @returns ISearchResult<IPinCodeExtension>
   */
  get getDeviceReadings(): ISearchResult<IDeviceReading> {
    return this.deviceReadings;
  }

  /**
   * Get device device readings
   * @returns ISearchResult<IDeviceReadingReport>
   */
  get getDeviceReadingsReport(): ISearchResult<IDeviceReadingReport> {
    return this.deviceReadingsReport;
  }

  @Mutation
  [Mutations.SET_DEVICE_READING_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_DEVICE_READINGS](readings: ISearchResult<IDeviceReading>) {
    this.deviceReadings = readings;
  }

  @Mutation
  [Mutations.SET_DEVICE_READINGS_REPORT](
    report: ISearchResult<IDeviceReadingReport>
  ) {
    this.deviceReadingsReport = report;
  }

  @Action
  [Actions.SEARCH_DEVICE_READINGS](param: IDeviceReadingSearchParam) {
    ApiService.setHeader();
    let query = "?";
    if (param.deviceId) {
      query += "deviceId=" + param.deviceId;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.locationId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationId;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.locationId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationId;
    }
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (param.hideNegativeValues != null) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=" + param.hideNegativeValues;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.DeviceReadings, query)
      .then(({ data }) => {
        switch (param.category.toLowerCase()) {
          case "devicedevicereadings":
            this.context.commit(Mutations.SET_DEVICE_DEVICEREADINGS, data);
            break;

          case "devicereadings":
          default:
            this.context.commit(Mutations.SET_DEVICE_READINGS, data);
            break;
        }
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_DEVICE_READING_ERROR, [
            "There was an error searching device readings.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_READING_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_DEVICE_READINGS_REPORT](param: IDeviceReadingSearchParam) {
    ApiService.setHeader();
    let query = "?";
    if (param.deviceId) {
      query += "deviceId=" + param.deviceId;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.locationId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationId;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.locationId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationId;
    }
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (param.hideNegativeValues != null) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=" + param.hideNegativeValues;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.direction) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "direction=" + param.direction;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.DeviceReadings + "/export", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEVICE_READINGS_REPORT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_DEVICE_READING_ERROR, [
            "There was an error loading digester readings export.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_READING_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_NET_WEIGHT_BY_DEVICE](param: IDeviceReadingSearchParam) {
    ApiService.setHeader();
    let query = "?utcOffsetMinutes=" + new Date().getTimezoneOffset();
    if (param.deviceId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "deviceId=" + param.deviceId;
    }
    if (param.clientId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "clientId=" + param.clientId;
    }
    if (param.locationId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationId;
    }
    if (param.resellerId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "resellerId=" + param.resellerId;
    }
    if (param.locationId) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "locationId=" + param.locationId;
    }
    if (param.startDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "startDate=" + param.startDate.toISOString();
    }
    if (param.endDate) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "endDate=" + param.endDate.toISOString();
    }
    if (param.hideNegativeValues != null) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "hideNegativeValues=" + param.hideNegativeValues;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.direction) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "direction=" + param.direction;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.Charts + "/netweightbydevice", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DEVICE_READINGS_REPORT, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_DEVICE_READING_ERROR, [
            "There was an error loading digester readings chart data.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_DEVICE_READING_ERROR, [message]);
      });
  }
}
