import { getRoles } from "@/core/helpers/permission";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import {
  IUser,
  IUserAuthInfo,
  ILoginToken,
  IUserAvatar,
  IPermission,
  IChangePassword,
} from "@/store/common/Types";
import { Actions, Mutations, Endpoints } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "..";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class AuthModule extends VuexModule implements IUserAuthInfo {
  errors = {};
  token = {} as ILoginToken;
  user = {} as IUser;
  userRole = "";
  avatar = {} as IUserAvatar;
  permission = {} as IPermission;
  isAuthenticated = !!JwtService.getToken();
  validKeyFlag = false;

  /**
   * Get current user object
   * @returns IUser
   */
  get currentUser(): IUser {
    return this.user;
  }

  /**
   * Get current user object
   * @returns IUserAvatar
   */
  get currentAvatar(): IUserAvatar {
    return this.avatar;
  }

  /**
   * Get current user object
   * @returns IUserAvatar
   */
  get currentUserRole(): string {
    return this.userRole;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getLoginErrors() {
    return this.errors;
  }

  /**
   * Get authentification token
   * @returns ILoginToken
   */
  get getLoginToken(): ILoginToken {
    return this.token;
  }

  /**
   * Get valid key flag
   * @returns boolean
   */
  get getValidKeyFlag(): boolean {
    return this.validKeyFlag;
  }

  /**
   * Get permission
   * @returns IPermission
   */
  get getPermission(): IPermission {
    return this.permission;
  }

  /**
   * Get userCanViewResellers permission
   * @returns boolean
   */
  get userCanViewResellers(): boolean {
    return this.permission ? this.permission.ResellerView : false;
  }

  /**
   * Get userCanCreateResellers permission
   * @returns boolean
   */
  get userCanCreateResellers(): boolean {
    return this.permission ? this.permission.ResellerCreate : false;
  }

  /**
   * Get userCanEditResellers permission
   * @returns boolean
   */
  get userCanEditResellers(): boolean {
    return this.permission ? this.permission.ResellerEdit : false;
  }

  /**
   * Get userCanViewLocations permission
   * @returns boolean
   */
  get userCanViewLocations(): boolean {
    return this.permission ? this.permission.LocationView : false;
  }

  /**
   * Get userCanCreateLocations permission
   * @returns boolean
   */
  get userCanCreateLocations(): boolean {
    return this.permission ? this.permission.LocationCreate : false;
  }

  /**
   * Get userCanEditLocations permission
   * @returns boolean
   */
  get userCanEditLocations(): boolean {
    return this.permission ? this.permission.LocationEdit : false;
  }

  /**
   * Get userCanEditLocationName permission
   * @returns boolean
   */
  get userCanEditLocationName(): boolean {
    return this.permission ? this.permission.LocationEditName : false;
  }

  /**
   * Get userCanViewDevices permission
   * @returns boolean
   */
  get userCanViewDevices(): boolean {
    return this.permission ? this.permission.DeviceView : false;
  }

  /**
   * Get userCanCreateDevices permission
   * @returns boolean
   */
  get userCanCreateDevices(): boolean {
    return this.permission ? this.permission.DeviceCreate : false;
  }

  /**
   * Get userCanEditDevices permission
   * @returns boolean
   */
  get userCanEditDevices(): boolean {
    return this.permission ? this.permission.DeviceEdit : false;
  }

  /**
   * Get userCanEditDeviceName permission
   * @returns boolean
   */
  get userCanEditDeviceName(): boolean {
    return this.permission ? this.permission.DeviceEditName : false;
  }

  /**
   * Get userCanViewClients permission
   * @returns boolean
   */
  get userCanViewClients(): boolean {
    return this.permission ? this.permission.ClientView : false;
  }

  /**
   * Get userCanCreateClients permission
   * @returns boolean
   */
  get userCanCreateClients(): boolean {
    return this.permission ? this.permission.ClientCreate : false;
  }

  /**
   * Get userCanEditClients permission
   * @returns boolean
   */
  get userCanEditClients(): boolean {
    return this.permission ? this.permission.ClientEdit : false;
  }

  /**
   * Get userCanCreatePinCodes permission
   * @returns boolean
   */
  get userCanCreatePinCodes(): boolean {
    return this.permission ? this.permission.PinCodeCreate : false;
  }

  /**
   * Get userCanEditPinCodes permission
   * @returns boolean
   */
  get userCanEditPinCodes(): boolean {
    return this.permission ? this.permission.PinCodeEdit : false;
  }

  /**
   * Get userCanCreateKpi permission
   * @returns boolean
   */
  get userCanCreateKpi(): boolean {
    return this.permission ? this.permission.KpiCreate : false;
  }

  /**
   * Get userCanEditKpi permission
   * @returns boolean
   */
  get userCanEditKpi(): boolean {
    return this.permission ? this.permission.KpiEdit : false;
  }

  /**
   * Get userCanCreateModelSizes permission
   * @returns boolean
   */
  get userCanCreateModelSizes(): boolean {
    return this.permission ? this.permission.ModelSizeCreate : false;
  }

  /**
   * Get userCanEditModelSizes permission
   * @returns boolean
   */
  get userCanEditModelSizes(): boolean {
    return this.permission ? this.permission.ModelSizeEdit : false;
  }

  /**
   * Get userCanCreateIndustries permission
   * @returns boolean
   */
  get userCanCreateIndustries(): boolean {
    return this.permission ? this.permission.IndustryCreate : false;
  }

  /**
   * Get userCanEditIndustries permission
   * @returns boolean
   */
  get userCanEditIndustries(): boolean {
    return this.permission ? this.permission.IndustryEdit : false;
  }

  /**
   * Get userCanCreateDevicePinTypes permission
   * @returns boolean
   */
  get userCanCreateDevicePinTypes(): boolean {
    return this.permission ? this.permission.DevicePinTypeCreate : false;
  }

  /**
   * Get userCanEditDevicePinTypes permission
   * @returns boolean
   */
  get userCanEditDevicePinTypes(): boolean {
    return this.permission ? this.permission.DevicePinTypeEdit : false;
  }

  /**
   * Get userCanCreateResellerAdmin permission
   * @returns boolean
   */
  get userCanCreateResellerAdmin(): boolean {
    return this.permission ? this.permission.ResellerAdminCreate : false;
  }

  /**
   * Get userCanCreateClientUser permission
   * @returns boolean
   */
  get userCanCreateClientUser(): boolean {
    return this.permission ? this.permission.ClientUserCreate : false;
  }

  /**
   * Get userCanCreateGlobalAdmin permission
   * @returns boolean
   */
  get userCanCreateGlobalAdmin(): boolean {
    return this.permission ? this.permission.GlobalAdminCreate : false;
  }

  /**
   * Get userCanCreateClientAdmin permission
   * @returns boolean
   */
  get userCanCreateClientAdmin(): boolean {
    return this.permission ? this.permission.ClientAdminCreate : false;
  }

  /**
   * Get userCanViewResellerDropdown permission
   * @returns boolean
   */
  get userCanViewResellerDropdown(): boolean {
    return this.permission ? this.permission.ViewResellerDropdown : false;
  }

  /**
   * Get userCanViewClientDropdown permission
   * @returns boolean
   */
  get userCanViewClientDropdown(): boolean {
    return this.permission ? this.permission.ViewClientDropdown : false;
  }

  /**
   * Get userCanUpdateUserIsActive permission
   * @returns boolean
   */
  get userCanUpdateUserIsActive(): boolean {
    return this.permission ? this.permission.UserIsActiveUpdate : false;
  }

  /**
   * Get userCanUpdateUserimitResellers permission
   * @returns boolean
   */
  get userCanUpdateUserimitResellers(): boolean {
    return this.permission ? this.permission.UserLimitResellersUpdate : false;
  }

  get userCanViewPinCodeGraphsUpdate(): boolean {
    return this.permission ? this.permission.UserCanViewPinCodeGraphsUpdate : false;
  }

  /**
   * Get userCanViewPinCode permission
   * @returns boolean
   */
  get userCanViewPinCode(): boolean {
    return this.permission ? this.permission.PinCodeView : false;
  }

  /**
   * Get userCanRestrictUserLocations permission
   * @returns boolean
   */
  get userCanRestrictUserLocations(): boolean {
    return this.permission ? this.permission.CanRestrictUserLocations : false;
  }

  /**
   * Get userCanEditGeography permission
   * @returns boolean
   */
  get userCanEditGeography(): boolean {
    return this.permission ? this.permission.CanEditGeography : false;
  }

  /**
   * Get userCanEditLanguageStrings permission
   * @returns boolean
   */
  get userCanEditLanguageStrings(): boolean {
    return this.permission ? this.permission.CanEditLanguageStrings : false;
  }

  /**
   * Get userCanViewNetWeightByDigesterGraph permission
   * @returns boolean
   */
  get userCanViewNetWeightByDigesterGraph(): boolean {
    return this.permission
      ? this.permission.CanViewNetWeightByDigesterGraph
      : false;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_AUTH](payload) {
    this.isAuthenticated = true;
    this.errors = {};
    JwtService.saveTokens(payload?.token, payload?.refreshToken);
  }

  @Mutation
  [Mutations.SET_TOKEN](token) {
    const tokenObj = JwtService.parseJWT(token);
    this.token = tokenObj as unknown as ILoginToken;
    if (this.token && this.token.scope) {
      JwtService.saveRoleStr(this.token.scope.toString());
    }
  }

  @Mutation
  [Mutations.SET_LOGIN_USER](user: IUser) {
    this.user = user;

    switch (this.user.roleId) {
      case store.getters.getGlobalAdministratorRoleId:
        this.userRole = "Global Administrator";
        break;

      case store.getters.getResellerAdministratorRoleId:
        this.userRole = "Partner Administrator";
        break;

      case store.getters.getClientAdministratorRoleId:
        this.userRole = "Client Administrator";
        break;

      case store.getters.getClientUserRoleId:
        this.userRole = "Client User";
        break;
    }
  }

  @Mutation
  [Mutations.SET_LOGIN_USER_AVATAR](avatar: IUserAvatar) {
    this.avatar = avatar;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as IUser;
    this.permission = {} as IPermission;
    this.errors = [];
    JwtService.destroyTokens();
  }

  @Mutation
  [Mutations.SET_PERMISSION](permission: IPermission) {
    this.permission = permission;
  }

  @Mutation
  [Mutations.SET_KEY_VALID_FLAG](flag: boolean) {
    this.validKeyFlag = flag;
  }

  @Action
  [Actions.LOGIN](credentials) {
    this.context.commit(Mutations.SET_ERROR, []);
    return ApiService.post(Endpoints.Token, credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
        this.context.commit(Mutations.SET_TOKEN, data.token);
      })
      .catch(({ response }) => {
        if (!response || response.status == 401) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error verifying your credentials",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_LOGIN_USER]() {
    this.context.commit(Mutations.SET_ERROR, []);
    if (!this.token || !this.token.userId) {
      this.context.commit(Mutations.SET_ERROR, [
        "There was an error fetching login user. UserId not set.",
      ]);
      return;
    }
    ApiService.setHeader();
    return ApiService.get(Endpoints.Users, this.token.userId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LOGIN_USER, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error fetching login user.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_LOGIN_USER_AVATAR]() {
    this.context.commit(Mutations.SET_ERROR, []);
    if (!this.token || !this.token.userId) {
      this.context.commit(Mutations.SET_ERROR, [
        "There was an error fetching login user avatar. UserId not set.",
      ]);
      return;
    }
    ApiService.setHeader();
    return ApiService.get(Endpoints.Users, this.token.userId + "/avatar")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_LOGIN_USER_AVATAR, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_LOGIN_USER_AVATAR, null);
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error fetching login user.",
          ]);
          return;
        } else if (response.status == 404) {
          return; //ignore if no avatar;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_PERMISSION]() {
    let permission = {} as IPermission;
    permission.Permissions = getRoles();
    permission.ResellerView = permission.Permissions.includes("ResellerView");
    permission.LocationCreate =
      permission.Permissions.includes("LocationCreate");
    permission.ClientEdit = permission.Permissions.includes("ClientEdit");
    permission.ResellerEdit = permission.Permissions.includes("ResellerEdit");
    permission.ResellerCreate =
      permission.Permissions.includes("ResellerCreate");
    permission.DeviceView = permission.Permissions.includes("DeviceView");
    permission.ClientCreate = permission.Permissions.includes("ClientCreate");
    permission.ClientView = permission.Permissions.includes("ClientView");
    permission.DeviceEdit = permission.Permissions.includes("DeviceEdit");
    permission.DeviceReadingUpload = permission.Permissions.includes(
      "DeviceReadingUpload"
    );
    permission.DeviceCreate = permission.Permissions.includes("DeviceCreate");
    permission.DeviceEditName =
      permission.Permissions.includes("DeviceEditName");
    permission.LocationEdit = permission.Permissions.includes("LocationEdit");
    permission.LocationEditName =
      permission.Permissions.includes("LocationEditName");
    permission.LocationView = permission.Permissions.includes("LocationView");
    permission.PinCodeCreate =
      permission.Permissions.includes("DevicePinCreate");
    permission.PinCodeEdit = permission.Permissions.includes("DevicePinEdit");
    permission.KpiCreate = permission.Permissions.includes("KpiCreate");
    permission.KpiEdit = permission.Permissions.includes("KpiEdit");
    permission.ModelSizeCreate =
      permission.Permissions.includes("ModelSizeCreate");
    permission.ModelSizeEdit = permission.Permissions.includes("ModelSizeEdit");
    permission.IndustryCreate =
      permission.Permissions.includes("IndustryCreate");
    permission.IndustryEdit = permission.Permissions.includes("IndustryEdit");
    permission.DevicePinTypeCreate = permission.Permissions.includes(
      "DevicePinTypeCreate"
    );
    permission.DevicePinTypeEdit =
      permission.Permissions.includes("DevicePinTypeEdit");
    permission.ResellerAdminCreate = permission.Permissions.includes(
      "CanCreateResellerAdministrator"
    );
    permission.ClientUserCreate = permission.Permissions.includes(
      "CanCreateClientUser"
    );
    permission.GlobalAdminCreate = permission.Permissions.includes(
      "CanCreateGlobalAdministrator"
    );
    permission.ClientAdminCreate = permission.Permissions.includes(
      "CanCreateClientAdministrator"
    );
    permission.ViewResellerDropdown = permission.Permissions.includes(
      "UICanViewResellerDropdown"
    );
    permission.ViewClientDropdown = permission.Permissions.includes(
      "UICanViewClientDropdown"
    );
    permission.UserIsActiveUpdate = permission.Permissions.includes(
      "CanChangeUserIsActive"
    );
    permission.UserLimitResellersUpdate = permission.Permissions.includes(
      "CanChangeUserLimitResellers "
    );
    permission.UserCanViewPinCodeGraphsUpdate = permission.Permissions.includes(
      "CanChangeUserViewPinCodeGraphs"
    );
    permission.PinCodeView = permission.Permissions.includes("PinCodeView");
    permission.CanRestrictUserLocations = permission.Permissions.includes(
      "CanRestrictUserLocations"
    );
    permission.CanEditGeography =
      permission.Permissions.includes("CanEditGeography");
    permission.CanEditLanguageStrings = permission.Permissions.includes(
      "CanEditLanguageStrings"
    );
    permission.CanViewNetWeightByDigesterGraph =
      permission.Permissions.includes("CanViewNetWeightByDigesterGraph");

    this.context.commit(Mutations.SET_PERMISSION, permission);
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_ERROR, [message]);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    this.context.commit(Mutations.SET_ERROR, []);
    return ApiService.post(
      Endpoints.Users + "/forgot?email=" + payload.email,
      payload
    )
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "We were unable to find a user with that email address. Please confirm you are entering the correct address.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_ERROR, [message]);
      });
  }

  @Action
  async [Actions.VALIDATE_KEY](key: string) {
    this.context.commit(Mutations.SET_ERROR, []);
    try {
      const query = "forgot/reset?key=" + key;
      const meta = await ApiService.head(Endpoints.Users, query);
      if (meta.headers && meta.headers["x-reset-key-valid"]) {
        const result = meta.headers["x-reset-key-valid"] == "True";
        this.context.commit(Mutations.SET_KEY_VALID_FLAG, result);
      }
    } catch (err) {
      this.context.commit(Mutations.SET_ERROR, err);
    }
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    const token = payload?.api_token;
    const refreshToken = payload?.refresh_token;

    if (!token) {
      this.context.commit(Mutations.PURGE_AUTH);
      return;
    }

    this.context.commit(Mutations.SET_TOKEN, token);
    if (!this.token) {
      this.context.commit(Mutations.PURGE_AUTH);
      return;
    }

    if (
      Date.now() >
      (this.token.exp as unknown as number) * 1000 //Expired.
    ) {
      this.context.commit(Mutations.PURGE_AUTH);
      return;
    }

    const refreshTokenMinutes = 30; //set 30 mins to refresh before expired.
    if (
      Date.now() + refreshTokenMinutes * 60000 >
      (this.token.exp as unknown as number) * 1000
    ) {
      ApiService.setHeader();
      const req = {
        refreshToken: refreshToken,
      } as any;
      return ApiService.post(Endpoints.Token + "/refresh", req)
        .then((data) => {
          this.context.commit(Mutations.SET_ERROR, {});
          this.context.commit(Mutations.SET_AUTH, data.data);
          this.context.commit(Mutations.SET_TOKEN, data.data.token);
        })
        .catch(({ response }) => {
          //We don't do anthing for natural expiration.
        });
    }
  }

  @Action
  [Actions.RESET_PASSWORD](user: IChangePassword) {
    this.context.commit(Mutations.SET_ERROR, []);
    //    ApiService.setHeader();
    const req = {
      password: {
        op: "Replace",
        value: user.password,
      },
    } as any;

    return ApiService.post(
      Endpoints.Users + "/forgot/reset?key=" + user.key,
      req
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        if (!response || response.status == 404) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error resetting the user password.",
          ]);
          return;
        }
        if (response.data && response.data.errors) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        }
      });
  }
}
