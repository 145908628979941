import ApiService from "@/core/services/ApiService";
import {
  IPinCodeType,
  IBaseModule,
  IPinCodeTypeSearchParam,
  ISearchResult,
} from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class PinCodeTypeModule
  extends VuexModule
  implements IBaseModule
{
  errors = {};
  pinCodeType = {} as IPinCodeType;
  pinCodeTypes = {} as ISearchResult<IPinCodeType>;

  /**
   * Get pin code type errors
   * @returns array
   */
  get getPinCodeTypeErrors() {
    return this.errors;
  }

  /**
   * Get pin code type
   * @returns ISearchResult<IPinCodeType>
   */
  get getPinCodeType(): IPinCodeType {
    return this.pinCodeType;
  }

  /**
   * Get pin code type options
   * @returns ISearchResult<IPinCodeType>
   */
  get getPinCodeTypes(): ISearchResult<IPinCodeType> {
    return this.pinCodeTypes;
  }

  @Mutation
  [Mutations.SET_PIN_CODE_TYPE_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_PIN_CODE_TYPE](pinCodeType: IPinCodeType) {
    this.pinCodeType = pinCodeType;
  }

  @Mutation
  [Mutations.SET_PIN_CODE_TYPES](pinCodeTypes: ISearchResult<IPinCodeType>) {
    this.pinCodeTypes = pinCodeTypes;
  }

  @Action
  [Actions.SEARCH_PIN_CODE_TYPES](param: IPinCodeTypeSearchParam) {
    ApiService.setHeader();
    let query = "?";
    if (param.description) {
      query += "description=" + param.description;
    }
    if (param.page > -1) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "page=" + param.page;
    }
    if (param.orderBy) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderBy=" + param.orderBy;
    }
    if (param.orderByDirection) {
      if (query.slice(-1) !== "?") {
        query += "&";
      }
      query += "orderByDirection=" + param.orderByDirection;
    }
    query += "&isDeleted=false";
    return ApiService.get(Endpoints.PinCodeTypes, query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PIN_CODE_TYPES, data);
      })
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_PIN_CODE_TYPE_ERROR, [
            "There was an error loading pin code type.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PIN_CODE_TYPE_ERROR, [message]);
      });
  }

  @Action
  [Actions.LOAD_PIN_CODE_TYPE](pinCodeTypeId: string) {
    ApiService.setHeader();
    return ApiService.get(Endpoints.PinCodeTypes, pinCodeTypeId)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PIN_CODE_TYPE, data);
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PIN_CODE_TYPE_ERROR, [message]);
      });
  }

  @Action
  [Actions.CREATE_PIN_CODE_TYPE](param: IPinCodeType) {
    this.context.commit(Mutations.SET_PIN_CODE_TYPE_ERROR, []);
    const req = {
      description: param.description,
    } as any;
    ApiService.setHeader();
    return ApiService.post(Endpoints.PinCodeTypes, req)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PIN_CODE_TYPE_ERROR, [message]);
      });
  }

  @Action
  [Actions.UPDATE_PIN_CODE_TYPE](param: IPinCodeType) {
    this.context.commit(Mutations.SET_PIN_CODE_TYPE_ERROR, []);
    const req = {
      description: {
        op: "Replace",
        value: param.description,
      },
    } as any;
    ApiService.setHeader();
    return ApiService.patch(
      Endpoints.PinCodeTypes + "/" + param.devicePinTypeId,
      req
    )
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PIN_CODE_TYPE_ERROR, [message]);
      });
  }

  @Action
  [Actions.DELETE_PIN_CODE_TYPE](param: IPinCodeType) {
    this.context.commit(Mutations.SET_PIN_CODE_TYPE_ERROR, []);
    ApiService.setHeader();
    return ApiService.delete(Endpoints.PinCodeTypes, param.devicePinTypeId)
      .then(({ data }) => {
        //Do nothing
      })
      .catch(({ response }) => {
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_PIN_CODE_TYPE_ERROR, [message]);
      });
  }
}
