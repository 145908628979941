import ApiService from "@/core/services/ApiService";
import { IEmail, IBaseModule } from "@/store/common/Types";
import { Actions, Endpoints, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, VuexModule } from "vuex-module-decorators";
import { getErrorMessage } from "@/core/helpers/util";

@Module
export default class EmailModule extends VuexModule implements IBaseModule {
  errors = {};

  /**
   * Get email errors
   * @returns array
   */
  get getEmailErrors() {
    return this.errors;
  }

  @Action
  [Actions.SEND_EMAIL](email: IEmail) {
    this.context.commit(Mutations.SET_ERROR, {});
    ApiService.setHeader();
    return ApiService.post(Endpoints.Email, email as any)
      .then(({ data }) => {})
      .catch(({ response }) => {
        if (!response) {
          this.context.commit(Mutations.SET_ERROR, [
            "There was an error sending an email.",
          ]);
          return;
        }
        const message = getErrorMessage(response);
        this.context.commit(Mutations.SET_ERROR, [message]);
      });
  }
}
